import { FileOutlined } from '@ant-design/icons';
import { Empty } from 'antd';
import { useTranslation } from "contexts/TranslationContext";

const NoData = () => {
  const { translate } = useTranslation();

  return (
    <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', height: '100%' }}>
      <Empty
        image={<FileOutlined style={{ fontSize: '48px', color: '#d9d9d9' }} />}
        description={<span>{translate('PROVIDERS.NO_DATA_FOUND')}</span>}
      />
    </div>
  );
};

export default NoData;
