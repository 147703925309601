import { ArrowLeftOutlined, ArrowRightOutlined, InboxOutlined, LoadingOutlined } from "@ant-design/icons";
import { Upload } from 'antd';
import axios from "axios";
import DocumentCard from "components/documentCard/documentCard";
import useAuthHook from "hooks/useAuthHook";
import View from "pages/protected/Settings/view";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetDocumentsQuery } from "services/auth";
import { Button } from "shared/components/Button";
import env from "shared/constants/env";
import routes, { appRoute } from "shared/constants/routes";
import formatDateDDMMYYYY from "utilities/formatDate";
import { useTranslation } from "../../contexts/TranslationContext";

const { Dragger } = Upload;

const UploadDocumentPage = () => {
  const { translate, language } = useTranslation();
  const { appId } = useParams();
  const navigate = useNavigate();
  const isRtl = language.dir === "rtl";
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadFile, setUploadFile] = useState(null);
  const [fileList, setFileList] = useState([]);
  const [isViewing, setIsViewing] = useState(false);
  const { getUser } = useAuthHook();

  const user = getUser();
  const userId = user ? String(user.IDB_USR_ID) : null;
  const navigateBack = () => {
    navigate(appRoute(routes.HOME));
  };

  useEffect(() => {
    if (!appId) {
      navigate(routes.HOME);
      toast.error("Application ID was not specified.");
      return;
    }
  }, [appId, navigate]);

  // Use the query hook directly
  const { data, isLoading, isError, refetch } = useGetDocumentsQuery({ id: userId, applId: appId }, { skip: !userId, refetchOnMountOrArgChanges: true });

  // Handle any errors with toast notification
  if (isError) {
    toast.error("Failed to fetch documents. Please check network and API.");
    setTimeout(() => {
      navigateBack()
    }, 1000)
  }

  const handleViewDocument = (file) => {
    setSelectedFile(file);
    setIsViewing(true);
  };

  const beforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      toast.error(translate("SETTINGS.ERROR_FILE_TYPE"));
      return true;
    }

    const isLt3M = file.size / 1024 / 1024 < 3;
    if (!isLt3M) {
      toast.error(translate("SETTINGS.ERROR_FILE_SIZE_3MB"));
      return true;
    }

    const fileName = file.name;
    const multipleExtensionsPattern = /(\.[^.\s]{2,4}){2,}$/;
    if (multipleExtensionsPattern.test(fileName)) {
      toast.error(translate("SETTINGS.ERROR_FILE_MULTIPLE_EXT"));
      return true;
    }
    const invalidFileNamePattern = /^[^.]+\.[^.]+\..+\.[^.]+$/;

    if (invalidFileNamePattern.test(fileName)) {
      toast.error(translate("SETTINGS.ERROR_FILE_MULTIPLE_EXT"));
      return true;
    }

    if (fileName.toLowerCase().includes('contract')) {
      toast.error(translate("SETTINGS.ERROR_FILE_NAME_CONTRACT"));
      return true
    }
    setUploadFile(file)
    return false;
  };

  const dragAndDropProps = {
    name: 'file',
    multiple: false,
    accept: "image/png,image/jpeg,image/jpg,application/pdf",
    beforeUpload,
    fileList,
    onChange(info) {
      if (info.file.status === 'error' || info.file.status === 'removed'){
        setFileList([])
        return
      }
      setFileList([info.file])
    },
    onDrop(e) {
      setFileList([e.dataTransfer.files])
    },
  };

  const handleRemove = () => {
    setFileList([]);
    setUploadFile(null)
  }

  const handleSave = async () => {
    if (!uploadFile) {
      toast.error(translate("SETTINGS.ERROR_NO_FILE_ADDED"));
      return true;
    }
    const formData = new FormData();
    formData.append("document", uploadFile);
    formData.append("appId", appId);
    const data = JSON.parse(localStorage.getItem("@@TAMWEEL_USER"));

    await axios
      .post(`${env.API_ENDPOINT}app/upload-document`, formData, {
        headers: {
          Authorization: `Bearer ${data.token}`,
        },
      })
      .then((res) => {
        refetch()
        setUploadFile(null)
        setFileList([])
        toast.success(translate("SETTINGS.FILE_UPLOAD_SUCCESS"));
      })
      .catch((err) => {
        console.log(err, "err in upload file");
        toast.error(translate("SETTINGS.FILE_UPLOAD_ERROR"));
      });
  }

  return (
    <div className="rounded-xl min-h-16 mb-4 px-4 py-8 bg-white items-center shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
      <div className="gap-3 flex items-center">
        <div
          className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10"
          onClick={navigateBack}
        >
          {isRtl ? (
            <ArrowRightOutlined className="text-black text-lg font-extrabold" />
          ) : (
            <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
          )}
        </div>
        <div className="text-black text-2xl font-semibold mt-3 mb-3">
          {translate("SETTINGS.DOCUMENT")}
        </div>
      </div>

      {isLoading ? (
        <div className="p-5 bg-white flex flex-col gap-4">
          <div className="flex gap-4">
            <LoadingOutlined />
            <span>{translate("SETTINGS.LOADING_DOCUMENT")}</span>
          </div>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
            {data.payload.map((file, index) => (
              <DocumentCard
                key={index}
                date={formatDateDDMMYYYY(file.createdOn)}
                documentName={file.fileName}
                onClick={() => handleViewDocument(file)}
              />
            ))}
          </div>
          {data?.payload?.length < 3 && (
            <>
              <div className="mt-4">
                <Dragger {...dragAndDropProps} onRemove={handleRemove}>
                  <p className="ant-upload-drag-icon">
                    <InboxOutlined />
                  </p>
                  <p className="ant-upload-text">  {translate("SETTINGS.DOCUMENTS_DRAG_DROP_HEADING")}</p>
                  <p className="ant-upload-hint">
                    {translate("SETTINGS.DOCUMENTS_DRAG_DROP_TEXT")}
                  </p>
                </Dragger>
              </div>
              <div className="flex items-center justify-end gap-2 mt-4">
                <Button
                  onClick={navigateBack}
                  color="primary"
                  mode="outline"
                  className="rounded-xl h-12 w-32"
                >
                  {translate("CREATE_REQUEST.CANCEL")}
                </Button>
                <Button
                  onClick={handleSave}
                  color="primary"
                  className="rounded-xl h-12 w-64"
                >
                  {translate("SETTINGS.SAVE")}
                </Button>
              </div>
            </>
          )}
        </>
      )}
      <View
        data={selectedFile ? Uint8Array.from(selectedFile.data.data) : null}
        fileName={selectedFile?.fileName}
        format={selectedFile?.format?.toLowerCase()}
        isOpen={isViewing}
        onClose={() => setIsViewing(false)}
      />
    </div>
  );
};

export default UploadDocumentPage;
