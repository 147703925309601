import { DownOutlined } from "@ant-design/icons";
import { Dropdown, Modal, Space } from "antd";
// import BellIcon from "assets/icons/bell.svg";
import LogoutIcon from "assets/icons/logout.svg";
import SettingsIcon from "assets/icons/settings.svg";
import Logo from "assets/svg/logo-color.svg";
import useAuthHook from "hooks/useAuthHook";
import { useEffect, useMemo, useState } from "react";
import { Link, Outlet, useLocation, useNavigate } from "react-router-dom"; // Import useLocation
import { toast } from "react-toastify";
import { useGetPreAppStatusQuery, useLogoutMutation } from "services/auth";
import * as LOCAL_STORAGE_KEYS from "shared/constants/localStorageKeys";
import routes, { appRoute } from "shared/constants/routes";
import { LANGUAGES, useTranslation } from "../../../contexts/TranslationContext";

const LanguageDropdown = () => {
  const { language, setLanguage } = useTranslation();

  const languages = useMemo(
    () =>
      Object.keys(LANGUAGES).map((key) => ({
        key: LANGUAGES[key]?.code,
        label: (
          <span onClick={() => setLanguage(LANGUAGES[key])}>
            {LANGUAGES[key]?.name}
          </span>
        ),
        disabled: language?.code === LANGUAGES[key]?.code,
      })),
    [language, setLanguage]
  );

  return (
    <Dropdown menu={{ items: languages }}>
      <Space>
        <span className="font-bold">{language?.shortname}</span>
        <DownOutlined />
      </Space>
    </Dropdown>
  );
};

export default function Layout({ children }) {
  const { logout } = useAuthHook();
  const [confirmModal, setConfirmModal] = useState(false);
  const [doLogout, { isLoading }] = useLogoutMutation();
  const { translate } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const { getUser } = useAuthHook();

  const user = getUser();
  const userId = user ? String(user.IDB_USR_ID) : null;

  const onLogout = () => {
    setConfirmModal(true);
  };

  const { data, isError, refetch } = useGetPreAppStatusQuery({ id: userId }, { skip: !userId, refetchOnMountOrArgChanges: true, refetchOnFocus: true, refetchOnReconnect: true,  });
  // Trigger refetch on route change
  useEffect(() => {
    if (userId) {
      refetch(); // Force refetch whenever the route changes
    }
  }, [location.pathname, refetch, userId]);

  if (isError) {
    toast.error("Failed to fetch pre app status. Please check network and API.");
  }

  useEffect(() => {
    const untouchedRoutes = ['/app/setting/personal-detail','/app/setting/address-detail','/app/setting/marital-status','/app/setting/eligibility'].includes(location?.pathname)
    if (data?.result?.completedSteps.length < 4 && !untouchedRoutes) {
      navigate(appRoute(routes.PRE_APP));
    }
  }, [data, location.pathname, navigate]);

  return (
    <>
      <div className="h-screen">
        {/* Splash background */}
        <div className="relative w-full h-16 new-home-bg"></div>

        {/* Main content container */}
        <main className="relative -mt-8 container mx-auto px-4 sm:px-6 lg:px-8">
          {/* Header with full width and proper padding */}
          <header className="w-full rounded-xl border border-gray-200 min-h-16 mb-4 px-6 py-8 bg-white flex items-center">
            <div>
              <Link to="/app/pre">
                <img alt="App logo" src={Logo} className="cursor-pointer" />
              </Link>
            </div>
            <div className="flex-grow"></div>
            <div className="flex items-center gap-4 sm:gap-6 lg:gap-8">
              <ul className="flex items-center gap-4 sm:gap-6 lg:gap-8">
                {location.pathname !== "/app/setting" && location.pathname !== "/app/pre" && (
                  <li className="cursor-pointer">
                    <Link to="/app/setting">
                      <img alt="Settings icon" src={SettingsIcon} />
                    </Link>
                  </li>
                )}
                <li className="cursor-pointer" onClick={onLogout}>
                  <img alt="Logout icon" src={LogoutIcon} />
                </li>
              </ul>
              <LanguageDropdown />
            </div>
          </header>

          {/* Render children */}
          <Outlet />
        </main>
      </div>

      {/* Confirmation Modal */}
      <Modal
        open={confirmModal}
        title={translate("HOME.ARE_YOU_SURE")}
        onCancel={() => setConfirmModal(false)}
        okType="dashed"
        okButtonProps={{ loading: isLoading }}
        onOk={async () => {
          const user = localStorage.getItem(LOCAL_STORAGE_KEYS.USER);
          const userId = JSON.parse(user)?.IDB_USR_ID;

          if (userId) {
            await doLogout({ userId });
            logout();
            setConfirmModal(false);
          }
        }}
      ></Modal>
    </>
  );
}
