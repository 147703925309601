import React, { useEffect, useState } from "react";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { useTranslation } from "../../../contexts/TranslationContext";
import FAQ_Card from "components/FAQ_Card/FAQ_Card";
import routes, { appRoute } from "shared/constants/routes";
import { useNavigate } from "react-router-dom";
import env from "shared/constants/env";

const FAQ = () => {
  const { translate } = useTranslation();
  const { language } = useTranslation();
  const navigate = useNavigate();
  const isRtl = language.dir === "rtl";
  const [faqData, setFaqData] = useState([]);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch(`${env.API_ENDPOINT}app/get-faq`)
      .then((res) => res.json())
      .then((data) => {

        // Access the `faqs` array directly
        if (data && Array.isArray(data.faqs)) {
          setFaqData(data.faqs);
        } else {
          console.error("Invalid data structure:", data);
        }
      })
      .catch((error) => console.error("Error fetching FAQ data:", error))
      .finally(() => setLoading(false));
}, []);


  const navigateBack = () => {
    navigate(appRoute(routes.SETTING_ROOT));
  };

  return (
    <div className="rounded-xl min-h-16 mb-4 px-4 py-8 bg-white items-center shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
      <div className="gap-3 flex items-center">
        <div
          className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10"
          onClick={navigateBack}
        >
          {isRtl ? (
            <ArrowRightOutlined className="text-black text-lg font-extrabold" />
          ) : (
            <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
          )}
        </div>
        <div className="text-[#161616] text-[22px] font-normal font-sans">
          {translate("SETTINGS.SETTINGS")}
        </div>
      </div>
      <div className="text-black text-2xl font-semibold mt-3 mb-3">
        {translate("SETTINGS.FAQ")}
      </div>
      <p className="text-black text-xl font-normal my-3">
        {translate("SETTINGS.FAQ_DES")}
      </p>
      {loading ? (
        <p>Loading FAQs...</p>
      ) : faqData.length > 0 ? (
        faqData.map((faq, index) => (
          <FAQ_Card
            key={faq.ID || index}
            question={language.code === "ar" ? faq.QUESTION_AR : faq.QUESTION_EN}
            answer={language.code === "ar" ? faq.ANSWER_AR : faq.ANSWER_EN}
          />
        ))
      ) : (
        <p>No FAQ data available.</p>
      )}
    </div>
  );
};

export default FAQ;
