import { BrowserRouter, Navigate, Route, Routes } from "react-router-dom";

import AcceptTerms from "pages/AcceptTerms";
import AccessDenied from "pages/AccessDenied";
import ForgotPassword from "pages/ForgotPassword";
import NotFound from "pages/NotFound";
import SignIn from "pages/SignIn";
import SignUp from "pages/SignUp";
import CreateRequest from "pages/protected/CreateRequest/CreateRequest";
import EmploymentDetails from "pages/protected/CreateRequest/EmploymentDetails";
import LoanDetails from "pages/protected/CreateRequest/LoanDetails";
import PersonalDetails from "pages/protected/CreateRequest/PersonalDetails";
import Home from "pages/protected/Home";

import { useTranslation } from "contexts/TranslationContext";
import AutoLoan from "pages/protected/CreateRequest/AutoLoan";
import Document from "pages/protected/CreateRequest/Document";
import FinancialDetails from "pages/protected/CreateRequest/FinancialDetails";
import Guarantors from "pages/protected/CreateRequest/Guarantors";
import Success from "pages/protected/CreateRequest/Success";
import Eligibility from "pages/protected/Settings/Eligibility";
import FAQ from "pages/protected/Settings/FAQ";
import Setting from "pages/protected/Settings/Setting";
import AddressDetails from "pages/protected/Settings/addressDetails";
import ChangePassword from "pages/protected/Settings/changePassword";
import ContactUs from "pages/protected/Settings/contactUs";
import DocumentList from "pages/protected/Settings/documentList";
import MaritalStatus from "pages/protected/Settings/maritalStatus";
import PersonalDetail from "pages/protected/Settings/personalDetail";
import TermsAndCondition from "pages/protected/Settings/termsAndCondition";
import UploadDocumentPage from "pages/protected/UploadDocumentPage";
import ViewContract from "pages/protected/ViewContract/ViewContract";
import ViewOffer from "pages/protected/ViewOffer/ViewOffer";
import ViewRequest from "pages/protected/ViewRequest/ViewRequest";
import { useLayoutEffect } from "react";
import { ProtectedResource } from "shared/components/ProtectedResource";
import routes from "shared/constants/routes";
import Layout from "shared/views/Layout/Layout";
import PreApp from "pages/protected/PreApplication"
import Partners from "pages/protected/Partners";
import Provider from "pages/protected/Partners/Provider";
import SuccessProvider from "pages/protected/Partners/Success";

export default function AppRouter() {
  const { language } = useTranslation();

  useLayoutEffect(() => {
    window.document.dir = language?.dir;
  }, [language?.dir]);

  return (
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Navigate to={routes.AUTH.SIGN_IN} />} />
        <Route
          path={routes.AUTH.FORGOT_PASSWORD}
          element={<ForgotPassword />}
        />
        <Route path={routes.AUTH.SIGN_IN} element={<SignIn />} />
        <Route path={routes.AUTH.SIGN_UP} element={<SignUp />} />
        <Route
          path={routes.AUTH.ACCEPT_TERMS_AND_COND}
          element={<AcceptTerms />}
        />

        <Route
          path={`/${routes.APP_ROOT}`}
          element={
            <ProtectedResource>
              <Layout />
            </ProtectedResource>
          }
        >
          <Route path="" element={<Navigate to={routes.HOME} />} />
          <Route path={routes.HOME} element={<Home />} />
          <Route path={routes.SETTING_ROOT} element={<Setting />} />
          <Route path={routes.SETTING.PERSONAL_DETAIL} element={<PersonalDetail />} />
          <Route path={routes.SETTING.ADDRESS_DETAIL} element={<AddressDetails />} />
          <Route path={routes.SETTING.MARITAL_STATUS} element={<MaritalStatus />} />
          <Route path={routes.SETTING.TERMS_AND_CONDITION} element={<TermsAndCondition />} />
          <Route path={routes.SETTING.ELIGIBILITY} element={<Eligibility />} />
          <Route path={routes.SETTING.CONTACT_US} element={<ContactUs />} />
          <Route path={routes.SETTING.DOCUMENT} element={<DocumentList />} />
          <Route path={routes.SETTING.FAQ} element={<FAQ />} />
          <Route path={routes.SETTING.CHANGE_PASSWORD} element={<ChangePassword />} />

          <Route path={routes.CREATE_REQUEST} element={<CreateRequest />}>
            <Route
              path=""
              element={<Navigate to={routes.CREATE_REQUEST_PERSONAL_DETAILS} />}
            />
            <Route
              path={routes.CREATE_REQUEST_PERSONAL_DETAILS}
              element={<PersonalDetails />}
            />
            <Route
              path={routes.CREATE_REQUEST_EMPLOYMENT_DETAILS}
              element={<EmploymentDetails />}
            />
            <Route
              path={routes.CREATE_REQUEST_FINANCIAL_DETAILS}
              element={<FinancialDetails />}
            />
            <Route
              path={routes.CREATE_REQUEST_LOAN_DETAILS}
              element={<LoanDetails />}
            />
            <Route
              path={routes.CREATE_REQUEST_AUTO_LOAN}
              element={<AutoLoan />}
            />
            <Route
              path={routes.CREATE_REQUEST_DOCUMENT_DETAILS}
              element={<Document />}
            />
            <Route
              path={routes.CREATE_REQUEST_GUARANTORS_DETAILS}
              element={<Guarantors />}
            />
            <Route path={routes.CREATE_REQUEST_SUCCESS} element={<Success />} />
          </Route>
          <Route
            path={routes.VIEW_REQUEST + "/:id"}
            element={<ViewRequest />}
          />
          <Route path={routes.VIEW_OFFER + "/:appId"} element={<ViewOffer />} />
          <Route
            path={routes.VIEW_CONTRACT + "/:appId/:contractStatus"}
            element={<ViewContract />}
          />
          <Route path={routes.UPLOAD_ADDITIONAL_DOCUMENT + "/:appId"} element={<UploadDocumentPage />} />
          <Route path={routes.PRE_APP} element={<PreApp />} />
          <Route path={routes.PARTNERS + "/:id"} element={<Partners />} />
          <Route path={routes.PARTNERS + "/:id/:partnerId/application"} element={<Provider />} />
          <Route path={routes.PARTNERS + "/:id/:partnerId/application" + "/:applId/success"} element={<SuccessProvider /> } />
        </Route>

        <Route path={routes.ACCESS_DENIED} element={<AccessDenied />} />
        <Route path="*" element={<NotFound />} />
      </Routes>
    </BrowserRouter>
  );
}
