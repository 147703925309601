import { configureStore } from "@reduxjs/toolkit";
import { api } from "services/auth";
import { lookupApi } from "services/lookup";
import authReducer from "store/auth";

export const store = configureStore({
  reducer: {
    [api.reducerPath]: api.reducer,
    [lookupApi.reducerPath]: lookupApi.reducer, // Add lookupApi reducer
    auth: authReducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(api.middleware, lookupApi.middleware), // Add lookupApi middleware
});

export default store;
