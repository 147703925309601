import { ArrowLeftOutlined, ArrowRightOutlined } from "@ant-design/icons";
import { useTranslation } from "contexts/TranslationContext";
import { useNavigate } from 'react-router-dom';
import routes, { appRoute } from "shared/constants/routes";
import MedicalProvider from "assets/svg/medical-provider.svg";

const ServiceCard = ({ id ,title, imageUrl }) => {
  const navigate = useNavigate();
  const { language } = useTranslation();
  const isRtl = language.dir === "rtl";

  const handleClick = () => {
    navigate(appRoute(routes.PARTNERS + `/${id}`));
  };

  return (
    <div
      className="relative w-full max-w-sm h-48 overflow-hidden shadow-md hover:shadow-lg transition rounded-3xl"
      style={{
        backgroundImage: `url(${imageUrl || MedicalProvider})`,
        backgroundSize: 'cover',
        backgroundPosition: 'center',
      }}
    >
      <div className="absolute bottom-0 left-0 right-0 bg-black bg-opacity-60 flex items-center justify-between p-4 cursor-pointer" onClick={handleClick}>
        <div className="flex items-center space-x-2">
          <div className="w-2 h-2 bg-blue-500 rounded-full"></div>
          <h3 className="text-white text-lg font-semibold">{title}</h3>
        </div>
        <button
          className="w-10 h-10 flex items-center justify-center bg-yellow-400 rounded-full hover:bg-yellow-500 transition"
        >
          {!isRtl ? <ArrowRightOutlined style={{ color: "#fff" }} /> : <ArrowLeftOutlined style={{ color: "#fff" }} />}
        </button>
      </div>
    </div>
  );
};

export default ServiceCard;
