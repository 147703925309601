import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  FormDrawer,
  buildInitialValues,
  buildValidationSchema,
} from "Components";
import { withFormik } from "formik";
import useAuthHook from "hooks/useAuthHook";
import { personalDetailsSchema } from "pages/protected/CreateRequest/schemas";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetAddressDetailMutation,
  useUpdateAddressDetailMutation,
} from "services/auth";
import { Button } from "shared/components/Button";
import env from "shared/constants/env";
import routes, { appRoute } from "shared/constants/routes";
import { useTranslation } from "../../../contexts/TranslationContext";

const AddressDetails = withFormik({
  mapPropsToValues: () =>
    buildInitialValues(
      personalDetailsSchema({
        region: [],
        city: []
      })
    ),
  validationSchema: buildValidationSchema(
    personalDetailsSchema({
      region: [],
      city: []
    })
  ),
  enableReinitialize: true,
  validateOnChange: true,
  validateOnMount: true,
})((props) => {
  const { translate, language } = useTranslation();
  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);
  const [cities, setCities] = useState([]);
  const [initialDataLoading, setInitialDataLoading] = useState(true);
  const isRtl = language.dir === "rtl"; // Check if the current language direction is RTL
  const { getUser } = useAuthHook();
  const [getAddressDetail] = useGetAddressDetailMutation();
  const [updateAddressDetail] = useUpdateAddressDetailMutation();
  const [lookupsLoaded, setLookupsLoaded] = useState(false);
  const [valuesFilled, setValuesFilled] = useState(false);
  const [fieldFlags, setFieldFlags] = useState({
    buildingNumFlag: false,
    streetNameFlag: false,
    district_nameFlag: false,
    additionalNumberFlag: false,
    postCodeFlag: false,
  });

  const navigateBack = () => {
    navigate(-1);
  };

  useEffect(() => {
    const updateFlags = () => {
      setFieldFlags({
        buildingNumFlag: !!props?.values?.buildingNum,
        streetNameFlag: !!props?.values?.streetName,
        district_nameFlag: !!props?.values?.district_name,
        additionalNumberFlag: !!props?.values?.additionalNumber,
        postCodeFlag: !!props?.values?.postCode,
      });
    };

    updateFlags();
  }, [props.values]);


  // Fetch regions from the API
  useEffect(() => {
    const fetchRegions = async () => {
      try {
        const response = await fetch(
          `${env.API_ENDPOINT}lookup/regions?status=A`
        );
        const data = await response.json();

        if (data.payload?.rows) {
          const regionOptions = data.payload.rows.map((region) => ({
            value: String(region.REGIONID),
            label: region.REGION,
            labelAr: region.REGION_AR,
          }));
          setRegions(regionOptions);
        } else {
          throw new Error("Regions data not found");
        }
        setLookupsLoaded(true);
      } catch (error) {
        toast.error("Failed to load regions.");
        console.error("Error fetching regions:", error);
        setTimeout(() => {
          navigateBack();
        }, 2000);
      } finally {
        // Ensure loading state is updated
        setInitialDataLoading(false);
      }
    };

    fetchRegions();
  }, []);

  // Fetch cities based on the selected region
  useEffect(() => {
    if (!props.values.region) return;

    const fetchCities = async () => {
      try {
        const response = await fetch(
          `${env.API_ENDPOINT}lookup/cities?districtid=${props.values.region}&status=A`
        );
        const data = await response.json();

        if (data.payload?.rows) {
          const cityOptions = data.payload.rows.map((city) => ({
            value: String(city.CITYID),
            label: city.CITY,
            labelAr: String(city.CITY_AR),
          }));
          setCities(cityOptions);
        } else {
          throw new Error("Cities data not found");
        }
      } catch (error) {
        toast.error("Failed to load cities.");
        console.error("Error fetching cities:", error);
      }
    };

    fetchCities();
    // set city to empty
    if (valuesFilled) {
      props.setValues((values) => ({
        ...values,
        city: null,
      }));
    }
    setValuesFilled(true);
  }, [props.values.region]);

  useEffect(() => {
    if (lookupsLoaded) {
      const fetchAddressDetail = async () => {
        try {
          const user = getUser();
          if (!user) return;

          const res = await getAddressDetail({
            id: String(user?.IDB_USR_ID),
            nationalId: String(user?.USER_ID)
          }).unwrap();

          if (res?.result) {
            const { REGIONID, CITYID, STREET_NAME, STREET_NAME_AR, BUILDING_NO, DISTRICT_NAME, DISTRICT_NAME_AR, ADDITIONAL_NO, POST_CODE } = res.result;

            setRegions((prevOptions) =>
              prevOptions.map((option) =>
                option.value === String(REGIONID)
                  ? { ...option, selected: true }
                  : option
              )
            );

            setCities((prevOptions) =>
              prevOptions.map((option) =>
                option.value === String(CITYID)
                  ? { ...option, selected: true }
                  : option
              )
            );

            props.setValues((values) => ({
              ...values,
              region: REGIONID ? String(REGIONID) : values.region,
              city: CITYID ? String(CITYID) : values.city,
              streetName: STREET_NAME_AR || STREET_NAME || values.streetName,
              buildingNum: BUILDING_NO || values.buildingNum,
              additionalNumber: ADDITIONAL_NO || values.additionalNumber,
              district_name: DISTRICT_NAME_AR || DISTRICT_NAME || values.district_name,
              postCode: POST_CODE || values.postCode
            }));
          }
        } catch (ex) {
          console.log("Error while fetching address detail", ex);
          toast.error("Failed to load address details.");
        }
      };

      fetchAddressDetail();
    }
  }, [lookupsLoaded]);

  const handleSave = async () => {
    const requiredFields = ["region", "city", "streetName", "buildingNum", "district_name", "postCode"];

    const allFieldsFilled = requiredFields.every((field) => props.values[field]);

    if (!allFieldsFilled) {
      toast.error(translate("VALIDATION.FILL_REQUIRED_FIELDS"));
      return;
    }

    try {
      const user = getUser();
      if (!user) return;

      const requestBody = {
        id: String(user.IDB_USR_ID),
        streetName: props.values.streetName,
        buildingNo: props.values.buildingNum,
        districtName: props.values.district_name,
        additionalNo: props.values.additionalNumber,
        postCode: props.values.postCode,
        regionId: props.values.region,
        cityId: props.values.city,
      };

      await updateAddressDetail(requestBody).unwrap();
      toast.success("Address details updated successfully.");
      setTimeout(() => {
        navigateBack()
      }, 1000)
    } catch (error) {
      console.error("Error updating address details:", error);
      toast.error("Failed to update address details.");
    }
  };

  // Schema for the address-related fields
  const schema = useMemo(() => {
    return personalDetailsSchema({
      ...fieldFlags,
      regions,
      cities,
      streetNameSpan: 8, // This will change the span for street name
      buildingNumSpan: 8, // This will change the span for building number
      additionalNumberSpan: 8, // This will change the span for additional number
      districtNameSpan: 8,
    }).filter((field) =>
      [
        "buildingNum",
        "unitNumber",
        "streetName",
        "district_name",
        "additionalNumber",
        "postCode",
        "region",
        "city",
      ].includes(field.key)
    );
  }, [regions, cities, fieldFlags]);

  if (initialDataLoading) {
    return (
      <div className="p-5 bg-white flex flex-col gap-4">
        <div className="flex gap-4">
          <LoadingOutlined />
          <span>{translate("CREATE_REQUEST.LOADING_ADDRESS_DETAILS")}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="rounded-xl min-h-16 mb-4 px-4 py-8 bg-white items-center shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
        <div className="gap-3 flex items-center">
          <div
            className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10"
            onClick={navigateBack}
          >
            {isRtl ? (
              <ArrowRightOutlined className="text-black text-lg font-extrabold" />
            ) : (
              <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
            )}
          </div>
          <div className="text-[#161616] text-[22px] font-normal font-sans">
            {translate("SETTINGS.SETTINGS")}
          </div>
        </div>
        <div className="text-black text-2xl font-semibold mt-3 mb-3">
          {translate("SETTINGS.ADDRESS_DETAIL")}
        </div>
        <div className="mb-4">
          <FormDrawer
            schema={{
              fields: schema, // Pass only address-related fields to FormDrawer
            }}
            values={props.values}
            onChange={props.handleChange}
          />
        </div>
        <div className="flex items-center justify-end gap-2">
          <Button
            onClick={navigateBack}
            color="primary"
            mode="outline"
            className="rounded-xl h-12 w-32"
          >
            {translate("CREATE_REQUEST.CANCEL")}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            className="rounded-xl h-12 w-64"
          >
            {translate("SETTINGS.SAVE")}
          </Button>
        </div>
      </div>
    </>
  );
});

export default AddressDetails;
