import {
  ArrowLeftOutlined,
  ArrowRightOutlined
} from "@ant-design/icons";
import { useTranslation } from "contexts/TranslationContext";

const BackButton = ({ onClick }) => {
  const { language } = useTranslation();

  const isRtl = language.dir === "rtl";
  return (
    <div
      className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10"
      onClick={onClick}
    >
      {isRtl ? (
        <ArrowRightOutlined className="text-black text-lg font-extrabold" />
      ) : (
        <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
      )}
    </div>
  )
}

export default BackButton