import { LoadingOutlined } from "@ant-design/icons";
import {
  FormDrawer,
  buildInitialValues,
  buildValidationSchema,
} from "Components";
import { withFormik } from "formik";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "../../../contexts/TranslationContext";
import { personalDetailsSchema } from "pages/protected/CreateRequest/schemas";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { Button } from "shared/components/Button";
import routes, { appRoute } from "shared/constants/routes";
import { useNavigate } from "react-router-dom";
import env from "shared/constants/env";
import { toast } from "react-toastify";
import useAuthHook from "hooks/useAuthHook";
import { useGetMaritalStatusMutation, useUpdateMaritalStatusMutation } from "services/auth";

const MaritalStatus = withFormik({
  mapPropsToValues: () =>
    buildInitialValues(
      personalDetailsSchema({
        maritalStatus: [],
        dependentOptions: [],
      })
    ),
  validationSchema: buildValidationSchema(
    personalDetailsSchema({
      maritalStatus: [],
      dependentOptions: [],
    })
  ),
  enableReinitialize: true,
  validateOnChange: true,
  validateOnMount: true,
})((props) => {
  const { translate } = useTranslation();
  const { language } = useTranslation();
  const navigate = useNavigate();
  const isRtl = language.dir === "rtl";
  const [initialDataLoading, setInitialDataLoading] = useState(true);
  const [maritalStatus, setMaritalStatus] = useState([]);
  const [dependentOptions, setDependentOptions] = useState([]);
  const { getUser } = useAuthHook();
  const [getMaritalStatus] = useGetMaritalStatusMutation();
  const [lookupsLoaded, setLookupsLoaded] = useState(false);
  const [updateMaritalStatus] = useUpdateMaritalStatusMutation();

  useEffect(() => {
    const fetchOptions = async () => {
      try {
        const [maritalStatusRes, dependentsRes] = await Promise.all([
          fetch(`${env.API_ENDPOINT}lookup/marital-status?status=A`),
          fetch(`${env.API_ENDPOINT}lookup/fam-size?status=A`),
        ]);

        const maritalStatusData = await maritalStatusRes.json();
        const dependentsData = await dependentsRes.json();

        if (maritalStatusData.payload?.rows) {
          const maritalStatusOptions = maritalStatusData.payload.rows.map(
            (status) => ({
              value: String(status.MARSTATID),
              label: status.MARSTAT,
              labelAr: status.MARSTAT_AR,
            })
          );
          setMaritalStatus(maritalStatusOptions);
        } else {
          throw new Error("Failed to load marital status.");
        }

        if (dependentsData.payload?.rows) {
          const numberOfDependentsOptions = dependentsData.payload.rows.map(
            (dependents) => ({
              value: String(dependents.FAMILYSIZEID),
              label: dependents.FAMILYSIZE,
              labelAr: dependents.FAMILYSIZE_AR,
            })
          );
          setDependentOptions(numberOfDependentsOptions);
        } else {
          throw new Error("Failed to load number of dependents.");
        }

        setLookupsLoaded(true);
      } catch (error) {
        toast.error("Failed to load options.");
        console.error("Error fetching options:", error);
      } finally {
        setInitialDataLoading(false);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    if (lookupsLoaded) {
      const fetchMaritalStatus = async () => {
        try {
          const user = getUser();
          if (!user) return;

          const res = await getMaritalStatus({
            id: String(user?.IDB_USR_ID),
          }).unwrap();

          if (res?.result ) {
            const { MARSTATID, FAMILYSIZEID } = res.result;
            if (!MARSTATID || !FAMILYSIZEID) return;
            setMaritalStatus((prevOptions) =>
              prevOptions.map((option) =>
                option.value === String(MARSTATID)
                  ? { ...option, selected: true }
                  : option
              )
            );
            setDependentOptions((prevOptions) =>
              prevOptions.map((option) =>
                option.value === String(FAMILYSIZEID)
                  ? { ...option, selected: true }
                  : option
              )
            );

            props.setValues((values) => ({
              ...values,
              maritalStatus: MARSTATID ? String(MARSTATID) : "",
              numberOfDependents: FAMILYSIZEID ? String(FAMILYSIZEID) : "",
            }));
          }
        } catch (ex) {
          console.log("Error while fetching marital status", ex);
          toast.error("Failed to load marital status.");
        }
      };

      fetchMaritalStatus();
    }
  }, [lookupsLoaded]);

  const handleSave = async () => {
    const requiredFields = ["maritalStatus", "numberOfDependents"];

    const allFieldsFilled = requiredFields.every((field) => props.values[field]);

    if (!allFieldsFilled) {
      toast.error(translate("VALIDATION.FILL_REQUIRED_FIELDS"));
      return;
    }

    try {
      const user = getUser();
      if (!user) return;

      const requestBody = {
        id: String(user.IDB_USR_ID),
        maritalStatus: props.values.maritalStatus,
        familySizeId: props.values.numberOfDependents,
      };

      // Call the mutation to update marital status
      await updateMaritalStatus(requestBody).unwrap();
      toast.success("Marital status updated successfully.");
      setTimeout(() => {
        navigateBack()
      }, 1000)
    } catch (error) {
      console.error("Error updating marital status:", error);
      toast.error("Failed to update marital status.");
    }
  };


  // Creating schema with updated options
  const schema = useMemo(() => {
    return personalDetailsSchema({
      maritalStatus,
      dependentOptions,
      maritalStatusSpan: 12,
      numberOfDependentSpan: 12,
    }).filter((field) =>
      ["maritalStatus", "numberOfDependents"].includes(field.key)
    );
  }, [maritalStatus, dependentOptions]);


   const navigateBack = () => {
    navigate(-1);
  };

  if (initialDataLoading) {
    return (
      <div className="p-5 bg-white flex flex-col gap-4">
        <div className="flex gap-4">
          <LoadingOutlined />
          <span>{translate("CREATE_REQUEST.LOADING_MARITAL_DETAILS")}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <div className="rounded-xl min-h-16 mb-4 px-4 py-8 bg-white items-center shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
        <div className="gap-3 flex items-center">
          <div
            className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10"
            onClick={navigateBack}
          >
            {isRtl ? (
              <ArrowRightOutlined className="text-black text-lg font-extrabold" />
            ) : (
              <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
            )}
          </div>
          <div className="text-[#161616] text-[22px] font-normal font-sans">
            {translate("SETTINGS.SETTINGS")}
          </div>
        </div>
        <div className="text-black text-2xl font-semibold mt-3 mb-3">
          {translate("SETTINGS.MARITAL_STATUS")}
        </div>
        <div className="mb-4">
          <FormDrawer
            schema={{
              fields: schema.filter((field) =>
                ["maritalStatus", "numberOfDependents"].includes(field.key)
              ), // Only include maritalStatus and numberOfDependents
            }}
          />
        </div>
        <div className="flex items-center justify-end gap-2">
          <Button
            onClick={navigateBack}
            color="primary"
            mode="outline"
            className="rounded-xl h-12 w-32"
          >
            {translate("CREATE_REQUEST.CANCEL")}
          </Button>
          <Button
            onClick={handleSave}
            color="primary"
            className="rounded-xl h-12 w-64"
          >
            {translate("SETTINGS.SAVE")}
          </Button>
        </div>
      </div>
    </>
  );
});

export default MaritalStatus;
