import { AppstoreOutlined, ClockCircleOutlined, EditOutlined, EyeOutlined, FolderOpenOutlined, TableOutlined, ToTopOutlined } from "@ant-design/icons";
import { Card, Col, Row, Tooltip } from "antd";
import ServiceCard from "components/Home/ServiceCard/ServiceCard";
import { useTranslation } from "contexts/TranslationContext";
import dayjs from "dayjs";
import { useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetPersonalInformationMutation, useGetUserAppsQuery } from "services/auth";
import { useGetBusinessProviderQuery } from "services/lookup";
import { Button } from "shared/components/Button";
import { Text17600 } from "shared/components/Text/Text";
import env from "shared/constants/env";
import * as LOCAL_STORAGE_KEYS from "shared/constants/localStorageKeys";
import routes, { appRoute } from "shared/constants/routes";
import RequestsFullCardsView from "../../components/Home/RequestsFullCardsView/RequestsFullCardsView";

const getStatusLabel = (statusId) => {
	switch (statusId) {
		case "D":
			return "Draft";
		case "S":
			return "Submitted";
		default:
			return "-";
	}
};

const RequestsViewToggleButton = (props) => {
	const isGrid = useCallback(() => props?.state === "grid", [props?.state]);

	return <Button onClick={props?.toggleRequestsView} icon={!isGrid() ? <AppstoreOutlined /> : <TableOutlined />}></Button>;
};

const RequestsGridCard = (props) => {
	const data = props?.data || {};
	const navigate = useNavigate();
	const { translate } = useTranslation();
	const isDraft = useCallback(() => data?.status?.toLowerCase() === "draft", [data?.status]);

	return (
		<Card
			title={`${translate("HOME.APPL_NO")}: ${data?.APPLID}`}
			extra={<Tooltip title={isDraft() ? "Draft" : "Ready"}>{isDraft() ? <ClockCircleOutlined /> : <ToTopOutlined />}</Tooltip>}
			actions={[
				<Button
					icon={isDraft() ? <EditOutlined /> : <EyeOutlined />}
					onClick={() => {
						//view Application
						if (data.APPLICATION_STATUS === "S") {
							navigate(`/app/${routes.VIEW_REQUEST}/${data.APPLID}`);
						}
					}}
					type='text'>
					{isDraft() ? translate("HOME.COMPLETE") : translate("HOME.VIEW")}
				</Button>,
			]}>
			<Row gutter={[8, 8]}>
				<Col span={24}>
					{translate("HOME.PRODUCT")}: <strong>{data?.PRODUCT_NAME ? `${data?.PRODUCT_NAME} / ${data?.PRODUCT_NAME_AR}` : "-"}</strong>
				</Col>
				<Col span={24}>
					{translate("HOME.CONTRACT")}: <strong>{data?.CONTRACT_STATUS || "-"}</strong>
				</Col>
				<Col span={24}>
					{translate("HOME.STATUS")}: <strong className={isDraft() ? "text-yellow-500" : "text-green-600"}>{data?.status || "-"}</strong>
				</Col>
			</Row>
		</Card>
	);
};

const RequestsGridView = (props) => {
	const requests = props?.requests || [];

	if (Array.isArray(props?.requests) && props?.requests.length === 0) {
		return (
			<div className='mt-6 text-gray-300 text-center flex flex-col gap-2'>
				<div>
					<FolderOpenOutlined style={{ fontSize: 32 }} />
				</div>
				<em>No data</em>
			</div>
		);
	}

	return (
		<Row gutter={[16, 16]}>
			{requests.map((request, i) => (
				<Col key={i} sm={24} lg={8}>
					<RequestsGridCard data={request} />
				</Col>
			))}
		</Row>
	);
};

export default function Home() {
	const [user, setUser] = useState({});
	// const [view, setView] = useState("fullCard");
	const { data, isLoading, refetch } = useGetUserAppsQuery(JSON.parse(localStorage.getItem("@@TAMWEEL_USER"))?.IDB_USR_ID);
	const [fetchPersonalInfo, { isLoading: personalInfoLoading }] = useGetPersonalInformationMutation();

	const { data: businessProviders } =
		useGetBusinessProviderQuery();

	const { translate, language } = useTranslation();

	const columnsV1 = [
		{
			title: translate("OFFER_TABLE.APP_NO"),
			dataIndex: "APPLID",
			key: "APPLID",
			sorter: () => true,
		},
		{
			title: translate("OFFER_TABLE.PRODUCT_NAME"),
			dataIndex: "productName",
			key: "productName",
			sorter: () => true,
		},
		{
			title: translate("OFFER_TABLE.CONTRACT"),
			dataIndex: "contractStatus",
			key: "contractStatus",
			sorter: () => true,
		},
		{
			title: translate("OFFER_TABLE.STATUS"),
			dataIndex: "status",
			key: "status",
			sorter: () => true,
		},
	];

	// const actionsv1 = [
	// 	{
	// 		id: 0,
	// 		icon: FileTextOutlined,
	// 		label: translate("OFFER_TABLE.CONTRACT"),
	// 		actionKey: "Contract",
	// 	},
	// 	{
	// 		id: 1,
	// 		icon: TagsOutlined,
	// 		label: translate("OFFER_TABLE.OFFER"),
	// 		actionKey: "Offer",
	// 	},
	// 	// {
	// 	// 	id: 2,
	// 	// 	icon: ReloadOutlined,
	// 	// 	label: translate("OFFER_TABLE.REFRESH"),
	// 	// 	actionKey: "refresh",
	// 	// },
	// 	{
	// 		id: 2,
	// 		icon: DeleteOutlined,
	// 		label: translate("OFFER_TABLE.DELETE"),
	// 		actionKey: "Delete",
	// 	},
	// 	// {
	// 	// 	id: 4,
	// 	// 	icon: FileTextOutlined,
	// 	// 	label: translate("OFFER_TABLE.VIEW_CONTRACT"),
	// 	// 	actionKey: "View Contract",
	// 	// },
	// 	{
	// 		id: 3,
	// 		icon: EditOutlined,
	// 		label: translate("OFFER_TABLE.EDIT"),
	// 		actionKey: "Edit",
	// 	},
	// ];

	// const toggleRequestsView = useCallback(() => {
	// 	setView((view) => (view === "grid" ? "table" : "grid"));
	// }, []);

	const navigate = useNavigate();

	let tableData = data?.map((row) => ({
		...row,
		productName: row?.PRODUCT_NAME ? `${row?.PRODUCT_NAME} / ${row?.PRODUCT_NAME_AR}` : "-",
		contractStatus: row?.CONTRACT_STATUS ? row?.CONTRACT_STATUS : "-",
		status: getStatusLabel(row?.APPLICATION_STATUS),
	}));

	useEffect(() => {
		let user;

		try {
			user = JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER));

			user.APP_CREATED = false;

			localStorage.setItem(LOCAL_STORAGE_KEYS.USER, JSON.stringify(user));
			setUser(user);
		} catch (e) {
			console.error(e);
		}
	}, [data]);

	useEffect(() => {
		setUser(JSON.parse(localStorage.getItem(LOCAL_STORAGE_KEYS.USER)));

		refetch();
	}, []);

	const onDelete = (appId) => {
		fetch(env.API_ENDPOINT + `app/delete-app?appId=${appId}`, {
			method: "DELETE",
			headers: {
				"Content-Type": "application/json",
			},
		})
			.then((res) => res.json())
			.then((res) => {
				if (res?.result?.deleted) {
					toast.success("App has been deleted.");
					refetch();
				} else {
					toast.error("Server Error");
				}
			});
	};
	const onEdit = (applId) => {
		navigate(appRoute(routes.CREATE_REQUEST + "/" + routes.CREATE_REQUEST_LOAN_DETAILS) + `?appl_=${applId}`);
	}

	const handleCreateNewApplication = async () => {
		if (data?.length > 0 && Array.isArray(data) && data.some(item => item.CONTRACT_STATUS !== 'SIGNED')) {
			toast.error(translate("APPLICATION_ALREADY_EXISTS"));
			return;
		}
		navigate(appRoute(routes.CREATE_REQUEST + "/" + routes.CREATE_REQUEST_LOAN_DETAILS));
		// if (data) {
		// 	try {
		// 		const response = await fetchPersonalInfo({ id: `${JSON.parse(localStorage.getItem("@@TAMWEEL_USER"))?.USER_ID}` }).unwrap().catch((res) => {
		// 			// console.log("Personal info err resp:", res);
		// 		});

		// 		// route to load-details with appl id and dispatch
		// 		if (response?.payload?.data?.address?.personNationalAddressInfo?.Addresses) {
		// 			navigate(appRoute(routes.CREATE_REQUEST + "/" + routes.CREATE_REQUEST_LOAN_DETAILS));
		// 		}
		// 		else {
		// 			toast.error(response?.payload?.data?.address?.error?.message || 'Please update your national address and try again');
		// 		}
		// 	} catch (error) {
		// 		//for now this will be error for personalInfo
		// 		// console.log("Personal info resp er:", error);
		// 		return toast.error(translate("Something went wrong"));
		// 	}
		// } else {
		// 	return toast.error(translate("Something went wrong"));
		// }
	};

	const noRequests = () => {
		if (!tableData || tableData.length === 0) {
			return (
				<div className={'no-result'}>
					<img src={'https://res.cloudinary.com/dlg8sln4t/image/upload/v1727442315/Group_3_okh2yo.png'} />
					<p>{translate('HOME.NO_REQUESTS')} </p>
				</div>
			)
		}
	}

	return (
		<>
			{/* Header Section */}
			<div className="relative new-home-bg">
				<div className="container mx-auto py-16 px-4 text-center">
					<h1 className="text-4xl text-white font-bold mb-4">
						{translate("HOME.Hi")},{" "}
						<strong>{user?.FIRST_NAME}</strong>!
					</h1>
					{user?.LAST_LOGIN && (
						<div className="text-white text-lg mb-6">
							{translate("HOME.LAST_LOGIN")}:{" "}
							<strong>{dayjs(user?.LAST_LOGIN).format("dddd, D MMM ")}</strong>
						</div>
					)}
					<Button
						onClick={handleCreateNewApplication}
						style={{ borderRadius: "16px" }}
						className="bg-yellow-500 text-teal-800 px-8 py-4 rounded-full text-lg font-semibold shadow-lg hover:bg-yellow-600 transition"
					>
						{translate("HOME.CREATE_NEW")}
					</Button>
				</div>
			</div>

			{/* Services Section */}
			<div className="container mx-auto py-16 px-4">
				<Text17600 text={translate("HOME.EXPLORE_SERVICES")} />
				<div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 pt-2">
					{businessProviders?.map((service) => (
						<ServiceCard key={service.BPTYPEID} id={service.BPTYPEID} title={service.BPTYPE} imageUrl={service.img} />
					))}
				</div>
			</div>

			{/* Request Section */}
			<section>
				<div className="bg-white rounded border p-6">
					<div className="flex items-center justify-between mb-4">
						<h2 className="text-2xl font-bold">
							{translate("HOME.REQUEST")}
						</h2>
					</div>

					{<RequestsFullCardsView onEdit={onEdit} onDelete={onDelete} requests={tableData} />}
					{!isLoading && noRequests()}
				</div>
			</section>
		</>
	);
}

