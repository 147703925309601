import { Modal } from "antd";
import { API_URL } from "constants";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useUpdateAppOfferMutation } from "services/auth";
import { Button } from "shared/components/Button";
import routes, { appRoute } from "shared/constants/routes";
import { LoadingOutlined } from "@ant-design/icons";
import { toast } from "react-toastify";
import { useTranslation } from "../../../contexts/TranslationContext";

export default function AcceptModal({ appId, kastleId }) {
  const [isOpen, setIsOpen] = useState(false);
  const { translate } = useTranslation();

  const navigate = useNavigate();
  const [updateAppOffer, { isLoading }] = useUpdateAppOfferMutation();

  const handleConfirm = async () => {
    //setIsOpen(false);
    //navigate(appRoute(routes.HOME));

    try {
      const response = await updateAppOffer({
        appId: appId,
        kastle_appl_id: kastleId,
        offerStatus: "ACCEPTED",
      }).then((res) => res.json());
      if (response?.data?.payload?.outBinds?.p_status) {
        toast.error(response?.data?.payload?.outBinds?.p_status)
      }
    } catch (ex) {
      toast.error("Unable to accept offer, please try again");
    } finally {
      setIsOpen(false);
      navigate(appRoute(routes.HOME));
    }
  };

  return (
    <>
      <Button onClick={() => setIsOpen(true)} color="primary" className="rounded-xl h-12 w-32">
        {translate("OFFER_TABLE.ACCEPT")}
      </Button>
      <Modal
        open={isOpen}
        title={translate("OFFER_TABLE.CONFIRM")}
        onCancel={() => setIsOpen(false)}
        footer={
          <Button disabled={isLoading} onClick={handleConfirm} color="primary" className="rounded-xl min-w-32">
            {isLoading ? <LoadingOutlined /> : translate("OFFER_TABLE.CONFIRM_BTN")}
          </Button>
        }
      >
        {translate("OFFER_TABLE.CONFIRM_ACCEPTANCE")}
      </Modal>
    </>
  );
}
