import React from 'react';

export const Text17600 = ({ text, style, ...props }) => {
  const defaultStyle = {
    // fontFamily: 'Calibre',
    fontSize: '17px',
    fontWeight: 600,
    lineHeight: '20.4px',
    textUnderlinePosition: 'from-font',
    textDecorationSkipInk: 'none',
    ...style,
  };

  return (
    <div style={defaultStyle} {...props}>
      {text}
    </div>
  );
};
