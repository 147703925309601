import { LoadingOutlined } from '@ant-design/icons';
import { FormDrawer, buildInitialValues, buildValidationSchema } from 'Components';
import { API_URL } from 'constants';
import { withFormik } from 'formik';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { PATCH_AUTO_LOAN_DETAILS, PATCH_PERSONAL_DETAILS, SET_ACTIVE_STEP_INDEX, SET_STEP_VALIDITY, useCreateRequestContext } from './CreateRequestContext';
import { autoLoanSchema as schema } from './schemas';
import moment from "moment-hijri";
import { useTranslation } from "../../../contexts/TranslationContext";
import env from 'shared/constants/env';

const AutoLoan = withFormik({
  mapPropsToValues: () => buildInitialValues(schema({})),
  validationSchema: buildValidationSchema(schema({})),
  validateOnMount: true,
  enableReinitialize: true
})(props => {
  const [loading, setLoading] = useState(false);

  const [manufacturerList, setManufacturerList] = useState([]);
  const [modelList, setModelList] = useState([]);
  const [modelTypeList, setModelTypeList] = useState([]);
  const [dealerList, setDealerList] = useState([]);
  const [colorList, setColorList] = useState([]);
  const [conditionList, setConditionList] = useState([]);
  const [yearList, setYearList] = useState([]);

  const [assetIds, setAssetIds] = useState([]);
  const { translate } = useTranslation();
  const { state, dispatch } = useCreateRequestContext();

  function generateYearArray(startYear) {
    const currentYear = new Date().getFullYear();
    const years = [];

    for (let year = startYear; year <= currentYear; year++) {
      years.push({ label: String(year), value: String(year) });
    }

    return years;
  }


  useEffect(() => {
    setLoading(true);
    setYearList(generateYearArray(2022));
    Promise.all([
      fetch(env.API_ENDPOINT + 'lookup/manufacturer?status=A'),
      fetch(env.API_ENDPOINT + 'lookup/dealers?status=A'),
      fetch(env.API_ENDPOINT + 'lookup/colors?status=A'),
    ])
      .then(([manufacturers, dealers, colors]) =>
        Promise.all([manufacturers.json(), dealers.json(), colors.json()]))
      .then(([manufacturers, dealers, colors]) => {
        setManufacturerList(manufacturers?.payload?.rows?.map(({ MFID, MFNAME, MFNAME_AR }) => ({
          label: String(MFNAME),
          value: String(MFID),
          labelAr: String(MFNAME_AR),
        })));

        setDealerList(dealers?.payload?.rows?.map(({ DEALERID, DEALER_NAME, DEALER_NAME_AR }) => ({
          label: String(DEALER_NAME),
          value: String(DEALERID),
          labelAr: String(DEALER_NAME_AR),
        })));

        setColorList(colors?.payload?.rows?.map(({ COLORID, COLOR_NAME, COLOR_NAME_AR }) => ({
          label: String(COLOR_NAME),
          value: String(COLORID),
          labelAr: String(COLOR_NAME_AR),
        })))
      })
      .catch(() => {
        toast.error('Could not load lookup.');
      })
      .finally(() => {
        setLoading(false);
      });
  }, []);

  useEffect(() => {
    // props.setFieldValue('assetModel', '');
    setModelList([]);

    if (props?.values?.assetManufacturer) {
      fetch(env.API_ENDPOINT + `lookup/models-by-manufacturer?status=A&mfId=${props?.values?.assetManufacturer}`)
        .then(models => models.json())
        .then(models => {
          setModelList(models?.payload?.rows?.map(({ ASSETMODELID, ASSETMODEL_NAME, ASSETMODEL_NAME_AR }) => ({
            label: String(ASSETMODEL_NAME),
            value: String(ASSETMODELID),
            labelAr: ASSETMODEL_NAME_AR
          })));
        })
        .catch(() => {
          toast.error('Lookup failed: Models');
        })
        .finally(() => { });
    }
  }, [props?.values?.assetManufacturer]); // eslint-disable-line

  useEffect(() => {
    // props.setFieldValue('assetModelType', '');
    if (props?.values?.assetModel) {
      fetch(env.API_ENDPOINT + `lookup/model-types-by-model?modelId=${props?.values?.assetModel}`)
        .then(models => models.json())
        .then(models => {
          setModelTypeList(models?.payload?.rows?.map(({ ASSETMODELTYPEID, ASSETMODELTYPE_NAME, ASSETMODELTYPE_NAME_AR }) => ({
            label: String(ASSETMODELTYPE_NAME),
            value: String(ASSETMODELTYPEID),
            labelAr: ASSETMODELTYPE_NAME_AR
          })));
        })
        .catch(() => {
          toast.error('Lookup failed: Model Types');
        })
        .finally(() => { });
    }
  }, [props?.values?.assetModel]); // eslint-disable-line

  useEffect(() => {
    // props.setFieldValue('assetCondition', '');
    if (props?.values?.assetModelType) {
      fetch(env.API_ENDPOINT + `lookup/conditions-by-model-type?modelId=${props?.values?.assetModel}&modelTypeId=${props?.values?.assetModelType}`)
        .then(conditions => conditions.json())
        .then(conditions => {
          setConditionList(conditions?.payload?.rows?.map(({ ASSETCONDITIONID, ASSETCONDITION_NAME, ASSETCONDITION_NAME_AR }) => ({
            label: String(ASSETCONDITION_NAME),
            value: String(ASSETCONDITIONID),
            labelAr: ASSETCONDITION_NAME_AR
          })));

          setAssetIds(
            conditions?.payload?.rows?.map(({ ASSETID, ASSETCONDITIONID }) => ({
              ASSETCONDITIONID: String(ASSETCONDITIONID),
              ASSETID: String(ASSETID),
            }))
          );
        })
        .catch(() => {
          toast.error('Lookup failed: Asset Conditions');
        })
        .finally(() => { });
    }
  }, [props?.values?.assetModelType]); // eslint-disable-line

  useEffect(() => {
    dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: 1 });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SET_STEP_VALIDITY,
      payload: {
        stepIndex: 1,
        valid: props.isValid,
      },
    });
  }, [dispatch, props.isValid]);

  useEffect(() => {
    props.setValues({
      ...state.autoLoanDetails,
    })
  }, [])

  useEffect(() => {
    const currentAsset = assetIds?.find(
      asset => asset?.ASSETCONDITIONID === String(props?.values?.assetCondition)
    );
    dispatch({
      type: PATCH_AUTO_LOAN_DETAILS,
      payload: {
        ...props.values,
        assetId: currentAsset?.ASSETID,
      },
    });

  }, [dispatch, props.values, assetIds]);

  const schemaFields = useMemo(
    () => {
      return schema({
        manufacturerList,
        modelList,
        modelTypeList,
        dealerList,
        colorList,
        conditionList,
        yearList,
      });
    },
    [manufacturerList, modelList, modelTypeList, dealerList, colorList, conditionList, yearList]
  );

  if (loading) {
    return (
      <div className="p-5 bg-white flex flex-col gap-4">
        <div className="flex gap-4">
          <LoadingOutlined />
          <span>{translate("CREATE_REQUEST.LOADING_ASSET_DETAILS")}</span>
        </div>
      </div>
    );
  }

  return (
    <>
      <h2 className="font-bold text-xl mb-4">{translate('CREATE_REQUEST.AUTO')}</h2>

      <div className="mb-4">
        <FormDrawer schema={{
          fields: schemaFields,
        }} />
      </div>
    </>
  );
});

export default AutoLoan;
