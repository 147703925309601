import {
  CheckCircleTwoTone,
  CloseOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  Checkbox,
  Col,
  DatePicker,
  Input,
  Modal,
  Row,
  Select,
  Typography,
  Radio,
} from "antd";
import { Formik } from "formik";
import {
  createContext,
  useContext,
  useEffect,
  useReducer,
  useState,
} from "react";
import { Link, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetStatusMutation,
  useValidateIdMutation,
  useValidatePhoneMutation,
  useRegisterMutation,
} from "services/auth";
import { Button } from "shared/components/Button";
import { errors } from "shared/constants/errors";
import routes from "shared/constants/routes";
import { Header } from "shared/views/Header";
import * as Yup from "yup";
import HijriDate, { toHijri } from "hijri-date/lib/safe";
import moment from "moment-hijri";
import dayjs from "dayjs";
import hijriDatePickerLocale from "shared/constants/hijriDatePickerLocale";
import { useTranslation } from "contexts/TranslationContext";
import HijriDateSelector from "../components/Inputs/DateSelector/HijriDateSelector";
import GeorgianDateSelector from "../components/Inputs/DateSelector/GeorgianDateSelector";
import PrivacyModal from "../components/PrivacyModal/PrivacyModal";
import hashedPaswword from "../utilities/hashedPassword";
import * as LOCAL_STORAGE_KEYS from 'shared/constants/localStorageKeys';
import { v4 as uuidv4 } from 'uuid';
import gaTrackingEvent from "../utilities/gaTrackingEvent";
import AdvertImg from "../assets/svg/AdvertImg.png"

const { Option } = Select;
const { Text } = Typography;

const toHijriApi = (dateObject) => {
  const year = dateObject.getFullYear();
  const month = (dateObject.getMonth() + 1).toString().padStart(2, "0");

  return `${year}-${month}`;
};

const RESET_STATE = "RESET_STATE";
const HIDE_MOBILE_PROMPT = "HIDE_MOBILE_PROMPT";
const SET_FORM_VALUE = "SET_FORM_VALUE";
const SET_ID = "SET_ID";
const SET_CODE = "SET_CODE";
const SET_TRANS_ID = "SET_TRANS_ID";
const SET_VERIFIED = "SET_VERIFIED";
const SET_PHONE = "SET_PHONE";
const SHOW_MOBILE_VERIFICATION = "SHOW_MOBILE_VERIFICATION";
const HIDE_MOBILE_VERIFICATION = "HIDE_MOBILE_VERIFICATION";
const SET_PHONE_NUMBER_VERIFICATION_MODAL_VISIBILITY =
  "SET_PHONE_NUMBER_VERIFICATION_MODAL_VISIBILITY";

const SignUpContext = createContext(null);

const INITIAL_STATE = {
  id: null,
  code: null,
  transId: null,
  verified: false,
  formValue: {},
  phone: null,
  isMobilePromptVisible: true,
  isMobileVerificationOpen: false,
  isPhoneNumberVerificationModalOpen: false,
};

const signUpReducer = (state, action) => {
  switch (action.type) {
    case SET_FORM_VALUE:
      return {
        ...state,
        formValue: action.payload,
      };
    case SET_PHONE_NUMBER_VERIFICATION_MODAL_VISIBILITY:
      return {
        ...state,
        isPhoneNumberVerificationModalOpen: action.payload,
      };
    case HIDE_MOBILE_PROMPT:
      return {
        ...state,
        isMobilePromptVisible: false,
      };
    case SHOW_MOBILE_VERIFICATION:
      return {
        ...state,
        isMobileVerificationOpen: true,
      };
    case HIDE_MOBILE_VERIFICATION:
      return {
        ...state,
        isMobileVerificationOpen: false,
      };
    case SET_ID:
      return {
        ...state,
        id: action.payload,
      };
    case SET_CODE:
      return {
        ...state,
        code: action.payload,
      };
    case SET_TRANS_ID:
      return {
        ...state,
        transId: action.payload,
      };
    case SET_PHONE:
      return {
        ...state,
        phone: action.payload,
      };
    case SET_VERIFIED:
      return {
        ...state,
        verified: action.payload,
      };
    case RESET_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};

const MobileDownloadPrompt = () => {
  const { state, dispatch } = useContext(SignUpContext);

  return (
    state.isMobilePromptVisible && (
      <div className="fixed z-50 bottom-8 right-8 bg-white rounded-full shadow-md px-8 py-4">
        <div className="flex justify-between items-center">
          <div className="text-secondary text-sm">Download</div>
          <div
            onClick={() => dispatch({ type: HIDE_MOBILE_PROMPT })}
            className="cursor-pointer"
          >
            <CloseOutlined />
          </div>
        </div>
        <div className="font-bold text-md text-black">
          Download our Cash Loan App
        </div>
      </div>
    )
  );
};

function ApplicationVerification() {
  const { state, dispatch } = useContext(SignUpContext);

  const STATUS_ENUM = {
    EXPIRED: "EXPIRED",
    WAITING: "WAITING",
    REJECTED: "REJECTED",
    COMPLETED: "COMPLETED",
  };

  const [getStatus, { isLoading: isGettingStatus }] = useGetStatusMutation();

  const onCheckStatus = async () => {
    try {
      const statusResponse = await getStatus({
        transId: state.transId,
        iqamaId: state.id,
        code: String(state.code),
      }).unwrap();

      if (statusResponse.status === STATUS_ENUM.WAITING) {
        toast.info("Check your phone for verification code.");
      }

      if (
        statusResponse.status === STATUS_ENUM.REJECTED ||
        statusResponse.status === STATUS_ENUM.EXPIRED
      ) {
        toast.error("Code was rejected or expired.");
        dispatch({ type: RESET_STATE });
      }

      if (statusResponse.status === STATUS_ENUM.COMPLETED) {
        toast.success("Verification is complete.");
        dispatch({ type: HIDE_MOBILE_VERIFICATION });
        dispatch({
          type: SET_PHONE_NUMBER_VERIFICATION_MODAL_VISIBILITY,
          payload: true,
        });
      }
    } catch (e) {
      toast.error("Server error.");
    }
  };

  return (
    <>
      <Modal
        closable={false}
        open={state.isMobileVerificationOpen}
        onCancel={() => dispatch({ type: HIDE_MOBILE_VERIFICATION })}
        footer={
          <>
            <div className="my-16 text-center text-4xl font-bold">
              {state.code}
            </div>
            <Button
              type="button"
              onClick={onCheckStatus}
              disabled={isGettingStatus}
              block
            >
              {isGettingStatus ? <LoadingOutlined /> : "OK"}
            </Button>
          </>
        }
        title={
          <div className="uppercase text-primary text-lg font-bold">
            Verify your number with NAFATH
          </div>
        }
      ></Modal>
    </>
  );
}

function TermsAndConditionsModal() {
  const [open, setOpen] = useState(false);
  const { translate } = useTranslation();

  // const TERMS = [
  //   "User has accepted the terms and conditions of registration. The Lender may decide to grant the loan only upon completion of the Lender?s prescribed Application Form (defined below), as maybe amended, from time to time. The Lender reserves its right to refuse or reject any Application Form, notwithstanding the Applicant (defined below) satisfying the eligibility criteria, if any, as set by the Lender for the sanction of the loan. The Terms and Conditions mentioned below shall apply to all loans granted/disbursed by the Lender and shall govern all the matters arising out of any business loans and/or unsecured loans (\"Loan\") sanctioned or granted or to be sanctioned or to be granted by the Lender. User has accepted the terms and conditions of registration.",
  // ];

  return (
    <>
      <span
        className="text-primary underline cursor-pointer"
        style={{ fontSize: "12px" }}
        onClick={() => setOpen(true)}
      >
        {translate("SIGN_UP.TERMS_MODEL.TITLE")}
      </span>
      <Modal
        closable={false}
        width={1000}
        open={open}
        onCancel={() => setOpen(false)}
        footer={
          <>
            <Button onClick={() => setOpen(false)} className="px-8">
              {translate("SIGN_UP.TERMS_MODEL.ACTION")}
            </Button>
          </>
        }
        title={
          <div className="uppercase text-primary text-lg font-bold">
            {translate("SIGN_UP.TERMS_MODEL.TITLE")}
          </div>
        }
      >
        <ol className="list-decimal p-6 max-h-96 overflow-y-scroll text-gray-500">
          {/* {
            TERMS.map((term, index) => <li key={index} className="mb-4">{term}</li>)
          } */}
          {translate("SIGN_UP.TERMS_MODEL.CONTENT")}
        </ol>
      </Modal>
    </>
  );
}

const PhoneNumberVerificationModal = () => {
  const { state, dispatch } = useContext(SignUpContext);

  const [validatePhone, { isLoading: isValidating }] =
    useValidatePhoneMutation();

  useEffect(() => {
    (async () => {
      if (!isValidating && state.isPhoneNumberVerificationModalOpen) {
        try {
          const response = await validatePhone({
            iqamaId: state.id,
            phone: state.phone,
          }).unwrap();

          if (response?.isOwner) {
            setTimeout(() => {
              dispatch({
                type: SET_PHONE_NUMBER_VERIFICATION_MODAL_VISIBILITY,
                payload: false,
              });
              dispatch({ type: SET_VERIFIED, payload: true });
            }, 1000);
          } else {
            toast.error(response?.message || "Phone validation failed.");
            dispatch({ type: RESET_STATE });
          }
        } catch (e) {
          toast.error("Server error.");
          dispatch({ type: RESET_STATE });
        }
      }
    })();
  }, [state.isPhoneNumberVerificationModalOpen]); // eslint-disable-line

  return (
    <Modal
      closable={false}
      title={
        <div className="uppercase text-primary text-lg font-bold">
          Verifying phone number & ID...
        </div>
      }
      footer={null}
      open={state.isPhoneNumberVerificationModalOpen}
    >
      {state?.verified ? <CheckCircleTwoTone /> : <LoadingOutlined />}
    </Modal>
  );
};



export default function SignUp() {
  const [isAdvertOpen, setIsAdvertOpen] = useState(false); //for advert
  const [state, dispatch] = useReducer(signUpReducer, INITIAL_STATE);
  const [validateId, { isLoading: isValidatingId }] = useValidateIdMutation();
  const [register, { isLoading: isRegistering }] = useRegisterMutation();
  const navigate = useNavigate();
  const { translate } = useTranslation();

  const shouldShowPopup = () => {
    const currentDate = new Date();
    const currentMonth = currentDate.getMonth();
    return currentMonth === 0; // Show only in January
  };



  useEffect(() => {
    if (shouldShowPopup())
      setIsAdvertOpen(true);
  }, []);


  // Device ID handling
  let deviceId = localStorage.getItem(LOCAL_STORAGE_KEYS.DEVICE_ID);
  if (!deviceId) {
    deviceId = uuidv4();
    localStorage.setItem(LOCAL_STORAGE_KEYS.DEVICE_ID, deviceId);
  }

  const initialValues = {
    id: "",
    password: "",
    confirmPassword: "",
    email: "",
    confirmEmail: "",
    hijriDate: "",
    georgianDate: "",
    phoneNumber: "",
    termsOfService: false,
    offers_enabled: false
  };

  const validationSchema = Yup.object().shape({
    id: Yup.string()
      .length(10, translate(errors.INCORRECT_LENGTH))
      .required(translate(errors.CANNOT_BE_EMPTY)),
    password: Yup.string()
      .required(translate(errors.CANNOT_BE_EMPTY))
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        translate("VALIDATION.PASSWORDS_DO_NOT_MATCH")
      ),
    confirmPassword: Yup.string()
      .required(translate(errors.CANNOT_BE_EMPTY))
      .oneOf(
        [Yup.ref("password"), null],
        translate("VALIDATION.PASSWORDS_DO_NOT_MATCH")
      ),
    email: Yup.string()
      .email(translate("VALIDATION.INVALID_EMAIL_ADDRESS"))
      .required(translate(errors.CANNOT_BE_EMPTY)),
    confirmEmail: Yup.string()
      .required(translate(errors.CANNOT_BE_EMPTY))
      .oneOf(
        [Yup.ref("email"), null],
        translate("VALIDATION.EMAILS_DO_NOT_MATCH")
      ),
    // hijriDate: Yup.string().required(translate(errors.CANNOT_BE_EMPTY)).test(
    //         'hijriDate-is-older-than-18',
    //         translate('VALIDATION.AGE_REQUIREMENT'),
    //         function(value) {
    //           if (!value) return false;
    //
    //           const inputDate = new Date(value);
    //           const today = new Date();
    //           let age = today.getFullYear() - inputDate.getFullYear();
    //           const monthDifference = today.getFullYear() - inputDate.getFullYear();
    //
    //           if (
    //               monthDifference < 0 ||
    //               (monthDifference === 0 && today.getDate() < inputDate.getDate())
    //           ) {
    //             age--;
    //           }
    //
    //           return age >= 18;
    //         }
    //     ),
    // georgianDate: Yup.string().required(translate(errors.CANNOT_BE_EMPTY)).test(
    //     'is-older-than-18',
    //     translate('VALIDATION.AGE_REQUIREMENT'),
    //     function(value) {
    //       if (!value) return false;
    //
    //
    //       const inputDate = new Date(value);
    //       const today = new Date();
    //       let age = today.getFullYear() - inputDate.getFullYear();
    //       const monthDifference = today.getFullYear() - inputDate.getFullYear();
    //       if (
    //           monthDifference < 0 ||
    //           (monthDifference === 0 && today.getDate() < inputDate.getDate())
    //       ) {
    //         age--;
    //       }
    //       return age >= 18;
    //     }
    // ),
    phoneNumber: Yup.string()
      .matches(/^966/, translate("VALIDATION.PHONE_MUST_START_WITH_966"))
      .test(
        "len",
        translate("VALIDATION.INVALID_PHONE_NUMBER"),
        (val) => val && val.length === 12
      )
      .required(translate(errors.CANNOT_BE_EMPTY)),
    termsOfService: Yup.boolean().oneOf(
      [true],
      translate(errors.MUST_BE_CHECKED)
    ),

  });

  const onGeorgianDateChange = (setFieldValue) => (date, dateString) => {
    const hijri = moment(dateString, "YYYY-MM-DD").format("iDD/iMM/iYYYY");
    setFieldValue("georgianDate", dateString); // Store as a string
    setFieldValue("hijriDate", hijri); // Store as a string in DD/MM/YYYY format
  };

  const onHijriDateChange = (setFieldValue) => (date, dateString) => {
    // Format the Hijri date to DD/MM/YYYY
    const hijriDateFormatted = moment(dateString, "iYYYY/iMM/iDD").format(
      "DD/MM/YYYY"
    );
    const georgianDate = moment(dateString, "iYYYY/iMM/iDD").format(
      "YYYY-MM-DD"
    );

    setFieldValue("hijriDate", hijriDateFormatted); // Store as a string in DD/MM/YYYY format
    setFieldValue("georgianDate", georgianDate); // Store as a string in YYYY-MM-DD format
  };

  useEffect(() => {
    (async () => {
      if (state?.verified) {
        try {
          const georgianDate = moment(
            state.formValue.georgianDate,
            "YYYY-MM-DD"
          ).toDate();
          const hijriDate = state.formValue.hijriDate; // Already stored as a string in DD/MM/YYYY format

          // Prepare the payload with the correct date formats
          const registrationResponse = await register({
            iqamaId: state.formValue.id,
            password: hashedPaswword(state.formValue.password),
            phone: state.formValue.phoneNumber,
            email: state.formValue.email,
            dobGeorgian: georgianDate.toLocaleDateString("en-GB"),
            dobHijri: hijriDate, // Use the converted Hijri date directly in DD/MM/YYYY format
            otp: state?.code,
            transId: state?.transId,
            offers_enabled: state?.formValue.offers_enabled,
            deviceId
          }).unwrap();

          if (registrationResponse?.isUserRegistered) {
            toast.success("Registration successful!");
            dispatch({ type: RESET_STATE });
            navigate(routes.AUTH.SIGN_IN);
          } else {
            toast.error("Registration failed.");
            dispatch({ type: RESET_STATE });
          }
        } catch (e) {
          switch (e?.data?.error?.code) {
            case "REGISTRATION_NOT_AUTHORIZED":
              toast.error("You are not authorized to register a new customer.");
              break;
            case "INVALID_DATE_FORMAT":
              toast.error(`${e?.data.error?.message.split('.')[0]}.`);
              break;
            case "YAQEEN_LOGIN_FAILED":
              toast.error("Unable to connect. Please try again later.");
              break;
            case "YAQEEN_FETCH_FAILED":
              toast.error("Invalid data entered, please correct submitted data.");
              break;
            case "USER_INSERT_FAILED":
              toast.error("Failed to register the user. Please try again later.");
              break;
            default:
              toast.error('Failed to register the user. Please try again later.')
              break;
          }
          // if (e?.data?.error?.code === "REGISTRATION_NOT_AUTHORIZED") {
          //   toast.error("You are not authorized to register a new customer.");
          // } else if (e?.data?.error?.code === "INVALID_DATE_FORMAT") {
          // toast.error(`${e?.data.error?.message.split('.')[0]}.`);
          // }
          dispatch({ type: RESET_STATE });
        }
      }
    })();
  }, [state?.verified]); // eslint-disable-line

  const onSubmit = async (values, _actions) => {
    gaTrackingEvent('register')
    try {
      const validationResponse = await validateId({
        deviceId,
        iqamaId: values.id,
      }).unwrap();

      if (validationResponse.transId) {
        toast.info("ID has been verified");

        dispatch({ type: SET_FORM_VALUE, payload: values });
        dispatch({ type: SET_ID, payload: values.id });
        dispatch({ type: SET_TRANS_ID, payload: validationResponse.transId });
        dispatch({ type: SET_CODE, payload: validationResponse.random });
        dispatch({ type: SHOW_MOBILE_VERIFICATION });
        dispatch({ type: SET_PHONE, payload: values.phoneNumber });
      } else {
        toast.error(
          translate(validationResponse?.error?.code) || "Unidentified Id"
        );
      }
    } catch (e) {
      toast.error(translate("Something went wrong"));
    }
  };

  const [selectedDateType, setSelectedDateType] = useState("georgianDate");

  const handleDateTypeChange = (event) => {
    setSelectedDateType(event.target.value);
  };

  return (
    <SignUpContext.Provider value={{ state, dispatch }}>
      <ApplicationVerification />
      {state.isPhoneNumberVerificationModalOpen && (
        <PhoneNumberVerificationModal />
      )}
      <section className="bg-primary with-splash-bg h-full">
        <div className="">
          <Header />
          <div className="tamweel-container flex flex-col items-center justify-center px-6 py-8 mx-auto md:h-screen lg:py-0">
            <div className="">
              <div className="auth_form_wrapper">
                <h1 className="form-header-text tamweel-text-22 font-bold leading-tight tracking-tight text-primary uppercase ">
                  {translate("SIGN_UP.TITLE")}
                </h1>
                <div className="bg-secondary w-16 h-1"></div>

                <div className="form-header-title-wrapper">
                  <h2 className="uppercase text-black text-3xl font-bold form-header-title">
                    <div>{translate("SIGN_UP.DESCRIPTION")}</div>
                  </h2>
                  <div className="text-gray-400 form-header-caption">
                    {translate("SIGN_UP.FORM_DESCRIPTION")}
                  </div>
                </div>

                <Formik
                  // validateOnMount:true
                  initialValues={initialValues}
                  validationSchema={validationSchema}
                  onSubmit={onSubmit}
                >
                  {({
                    handleSubmit,
                    handleChange,
                    handleBlur,
                    touched,
                    values,
                    errors,
                    setFieldValue,
                    isValid,
                    dirty
                  }) => (
                    <form
                      onSubmit={handleSubmit}
                      className="space-y-4 md:space-y-6"
                    >
                      <Row gutter={[12, 12]}>
                        <Col xs={24} lg={12}>
                          <div>
                            <Input
                              className={"tamweel-base-input"}
                              status={touched.id && errors.id ? "error" : null}
                              onChange={handleChange}
                              onBlur={handleBlur}
                              name="id"
                              id="id"
                              value={values.id}
                              placeholder={translate("SIGN_UP.FIELDS.ID")}
                            />
                            {errors.id && touched.id && (
                              <Text type="danger">{errors.id}</Text>
                            )}
                          </div>
                        </Col>
                        <Col xs={24} lg={12}>
                          <div>
                            <Input
                              status={
                                touched.phoneNumber && errors.phoneNumber
                                  ? "error"
                                  : null
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.phoneNumber}
                              className="w-full tamweel-base-input"
                              name="phoneNumber"
                              id="phoneNumber"
                              placeholder={translate("SIGN_UP.FIELDS.PHONE")}
                            />
                            {errors.phoneNumber && touched.phoneNumber && (
                              <Text type="danger">{errors.phoneNumber}</Text>
                            )}
                          </div>
                        </Col>
                        <Col xs={24} lg={12}>
                          <div>
                            <Input
                              className={"tamweel-base-input"}
                              status={
                                touched.email && errors.email ? "error" : null
                              }
                              onChange={(e) => {
                                const { value } = e.target;
                                handleChange(e);
                                if (value.length > 50) {
                                  setFieldValue("email", value.slice(0, 50)); // Truncate to 30 characters
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.email}
                              name="email"
                              id="email"
                              placeholder={translate("SIGN_UP.FIELDS.EMAIL")}
                              maxLength={50} // This limits the input to 50 characters
                            />
                            {errors.email && touched.email && (
                              <Text type="danger">{errors.email}</Text>
                            )}
                          </div>
                        </Col>
                        <Col xs={24} lg={12}>
                          <div>
                            <Input
                              className={"tamweel-base-input"}
                              status={
                                touched.confirmEmail && errors.confirmEmail
                                  ? "error"
                                  : null
                              }
                              onChange={(e) => {
                                const { value } = e.target;
                                handleChange(e);
                                if (value.length > 50) {
                                  setFieldValue("confirmEmail", value.slice(0, 50)); // Truncate to 30 characters
                                }
                              }}
                              onBlur={handleBlur}
                              value={values.confirmEmail}
                              name="confirmEmail"
                              id="confirmEmail"
                              placeholder={translate(
                                "SIGN_UP.FIELDS.CONFIRM_EMAIL"
                              )}
                              maxLength={50}
                            />
                            {errors.confirmEmail && touched.confirmEmail && (
                              <Text type="danger">{errors.confirmEmail}</Text>
                            )}
                          </div>
                        </Col>
                        <Col xs={24} lg={12}>
                          <div>
                            <Input.Password
                              className={"tamweel-base-input"}
                              status={
                                touched.password && errors.password
                                  ? "error"
                                  : null
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.password}
                              name="password"
                              id="password"
                              placeholder={translate("SIGN_UP.FIELDS.PASSWORD")}
                            />
                            {errors.password && touched.password && (
                              <Text type="danger">{errors.password}</Text>
                            )}
                          </div>
                        </Col>
                        <Col xs={24} lg={12}>
                          <div>
                            <Input.Password
                              className={"tamweel-base-input"}
                              status={
                                touched.confirmPassword &&
                                  errors.confirmPassword
                                  ? "error"
                                  : null
                              }
                              onChange={handleChange}
                              onBlur={handleBlur}
                              value={values.confirmPassword}
                              name="confirmPassword"
                              id="confirmPassword"
                              placeholder={translate(
                                "SIGN_UP.FIELDS.CONFIRM_PASSWORD"
                              )}
                            />
                            {errors.confirmPassword &&
                              touched.confirmPassword && (
                                <Text type="danger">
                                  {errors.confirmPassword}
                                </Text>
                              )}
                          </div>
                        </Col>
                        {/* Choose Date */}

                        <Col xs={24}>
                          <Radio.Group
                            value={selectedDateType}
                            onChange={handleDateTypeChange}
                            className="w-full"
                          >
                            <Radio value="georgianDate">
                              {translate("SIGN_UP.FIELDS.GEORGIAN_DATE")}
                            </Radio>
                            <Radio value="hijriDate">
                              {translate("SIGN_UP.FIELDS.HIJRI_DATE")}
                            </Radio>
                          </Radio.Group>
                        </Col>

                        {selectedDateType === "georgianDate" && (
                          <Col xs={24}>
                            <GeorgianDateSelector
                              onBlur={handleBlur}
                              onDateChange={(date) => {
                                const hijriDate = moment(
                                  date,
                                  "YYYY-MM-DD"
                                ).format("iDD/iMM/iYYYY");
                                setFieldValue("georgianDate", date);
                                setFieldValue("hijriDate", hijriDate);
                              }}
                              defaultValue={values.georgianDate}
                              // status={touched.georgianDate && errors.georgianDate ? "error" : null}
                              id="georgianDate"
                              name="georgianDate"
                            />
                            {/*{errors.georgianDate && touched.georgianDate && (*/}
                            {/*  <Text type="danger">{errors.georgianDate}</Text>*/}
                            {/*)}*/}
                            {/*<div>*/}
                            {/*  <DatePicker*/}
                            {/*      onChange={(date) => {*/}
                            {/*        const convertDate = moment(date.$d).format("YYYY-MM-DD");*/}
                            {/*        const hijriDate = moment(convertDate).format("iYYYY/iMM/iDD");*/}
                            {/*        setFieldValue('georgianDate', date)*/}
                            {/*        setFieldValue('hijriDate', hijriDate)}*/}
                            {/*      }*/}
                            {/*      onBlur={handleBlur}*/}
                            {/*      value={values.georgianDate}*/}
                            {/*      id="georgianDate"*/}
                            {/*      name="georgianDate"*/}
                            {/*      className="w-full"*/}
                            {/*      placeholder={translate("SIGN_UP.FIELDS.GEORGIAN_DATE")}*/}
                            {/*  />*/}
                            {/*</div>*/}
                          </Col>
                        )}

                        {selectedDateType === "hijriDate" && (
                          <Col
                            xs={24}
                            style={{
                              opacity: selectedDateType === "hijriDate" ? 1 : 0,
                            }}
                          >
                            <HijriDateSelector
                              defaultValue={values.hijriDate}
                              id="hijriDate"
                              name="hijriDate"
                              // status={
                              //   touched.hijriDate && errors.hijriDate ? "error" : null
                              // }
                              onDateChange={(date) => {
                                const gregorianDate = moment(date, "iDD/iMM/iYYYY").format("YYYY/MM/DD");
                                setFieldValue("georgianDate", gregorianDate);
                                setFieldValue("hijriDate", date);
                              }}
                            />
                            {/*{errors.hijriDate && touched.hijriDate && (*/}
                            {/*    <Text type="danger">{errors.hijriDate}</Text>*/}
                            {/*)}*/}
                            {/*<div>*/}
                            {/*  <DatePicker*/}
                            {/*      style={{*/}
                            {/*        color: "rgba(0, 0, 0, 0.85)",*/}
                            {/*        backgroundColor: "#fff",*/}
                            {/*        borderColor: "rgba(0, 0, 0, 0.25)",*/}
                            {/*      }}*/}
                            {/*      status={*/}
                            {/*        touched.hijriDate && errors.hijriDate ? "error" : null*/}
                            {/*      }*/}
                            {/*      onChange={(date) => setFieldValue('hijriDate', date)}*/}
                            {/*      type="date"*/}
                            {/*      value={values.hijriDate}*/}
                            {/*      id="hijriDate"*/}
                            {/*      name="hijriDate"*/}
                            {/*      className="w-full"*/}
                            {/*      placeholder={translate("SIGN_UP.FIELDS.HIJRI_DATE")}*/}
                            {/*      locale={hijriDatePickerLocale}*/}
                            {/*  />*/}
                            {/*  {errors.hijriDate && touched.hijriDate && (*/}
                            {/*      <Text type="danger">{errors.hijriDate}</Text>*/}
                            {/*  )}*/}
                            {/*</div>*/}
                          </Col>
                        )}
                        <Col span={24}>
                          <Checkbox
                            className={"tamweel-base-checkbox"}
                            id="termsOfService"
                            name="termsOfService"
                            checked={values.termsOfService}
                            onBlur={handleBlur}
                            onChange={(event) =>
                              setFieldValue(
                                "termsOfService",
                                event.target.checked
                              )
                            }
                          >
                            {translate("SIGN_UP.TERMS_AGREE")}
                          </Checkbox>
                          <span>
                            <PrivacyModal /> <span className='text-primary text-sm'>& </span>
                          </span>
                          <span className={'text-primary'}></span>
                          <span>
                            <TermsAndConditionsModal />
                          </span>

                          <div>
                            {errors.termsOfService &&
                              touched.termsOfService && (
                                <Text type="danger">
                                  {errors.termsOfService}
                                </Text>
                              )}
                          </div>
                        </Col>
                        {/*<Col span={24}>*/}
                        {/*  <Checkbox*/}
                        {/*    className={"tamweel-base-checkbox"}*/}
                        {/*    id="serviceUsage"*/}
                        {/*    name="serviceUsage"*/}
                        {/*    checked={values.serviceUsage}*/}
                        {/*    onBlur={handleBlur}*/}
                        {/*    onChange={(event) =>*/}
                        {/*      setFieldValue(*/}
                        {/*        "serviceUsage",*/}
                        {/*        event.target.checked*/}
                        {/*      )*/}
                        {/*    }*/}
                        {/*  >*/}
                        {/*    {translate(*/}
                        {/*      "SIGN_UP.NATIONAL_INFORMATION_ACCEPTENCE"*/}
                        {/*    )}*/}
                        {/*  </Checkbox>*/}
                        {/*  <div>*/}
                        {/*    {errors.serviceUsage && touched.serviceUsage && (*/}
                        {/*      <Text type="danger">{errors.serviceUsage}</Text>*/}
                        {/*    )}*/}
                        {/*  </div>*/}
                        {/*</Col>*/}
                        <Col span={24}>
                          <Checkbox
                            className={"tamweel-base-checkbox"}
                            id="offers_enabled"
                            name="offers_enabled"
                            checked={values.offers_enabled}
                            onBlur={handleBlur}
                            onChange={(event) =>
                              setFieldValue(
                                "offers_enabled",
                                event.target.checked
                              )
                            }
                          >
                            {translate(
                              "SIGN_UP.NEWS_AND_OFFERS_CONSENT"
                            )}
                          </Checkbox>

                        </Col>
                      </Row>

                      <div className={"form-button"}>
                        <Button
                          className={"tamweel-button mb-4"}
                          disabled={
                            Object.keys(errors).length > 0 || isValidatingId || !dirty || !isValid
                          }
                          type="submit"
                          block
                        >
                          {isValidatingId ? (
                            <LoadingOutlined />
                          ) : (
                            `${translate("SIGN_UP.FROM_BUTTON")}`
                          )}
                        </Button>
                        <div className="text-center text-sm text-gray-400">
                          <span style={{ fontSize: "13px" }}>
                            {translate("SIGN_UP.ALREADY_ACCOUNT")}
                          </span>
                          <Link
                            style={{ fontSize: "11px" }}
                            className="text-primary font-bold mx-1 underline"
                            to={routes.AUTH.SIGN_IN}
                          >
                            {translate("SIGN_UP.SIGN_IN")}
                          </Link>
                        </div>
                      </div>
                    </form>
                  )}
                </Formik>
                <p>{translate("SIGN_UP.HELP_LINE")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <Modal
        title={null}
        open={isAdvertOpen}
        onCancel={() => setIsAdvertOpen(false)}
        footer={null}
        closable={true}
        centered
      >
        <img
          src={AdvertImg}
          alt="Advertisement"
          style={{ width: "100%", height: "100%" }}
        />
      </Modal>
    </SignUpContext.Provider>
  );
}
