import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  CheckOutlined,
  EditOutlined,
} from "@ant-design/icons";
import { FormDrawer, buildInitialValues, buildValidationSchema } from "Components";
import { withFormik } from "formik";
import useAuthHook from "hooks/useAuthHook";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetpersonalInfoMutation,
  useUpdateGeneralInfoMutation,
  useUpdateUserEmailMutation,
} from "services/auth";
import { Button } from "shared/components/Button";
import env from "shared/constants/env";
import formatDateDDMMYYYY from "utilities/formatDate";
import * as Yup from "yup";
import { useTranslation } from "../../../contexts/TranslationContext";

const settingsPersonalDetailSchema = ({
  titleOptions = [],
  nationalities = [],
  onNationalityChange = () => { },
}) => [
    {
      type: "select",
      key: "title",
      label: "Title",
      required: true,
      options: titleOptions,
      span: 8,
      value: "1007",
    },
    {
      type: "select",
      key: "nationality",
      label: "Nationality",
      span: 8,
      required: true,
      options: nationalities,
      props: {
        showSearch: true,
        filterOption: (input, option) =>
          option?.label.toLowerCase().indexOf(input.toLowerCase()) >= 0,
        onChange: onNationalityChange,
      },
    },
    {
      type: "radio",
      key: "gender",
      label: "Gender",
      required: true,
      options: [
        { label: "Male", labelAr: "ذكر", value: "M" },
        { label: "Female", labelAr: "أنثى", value: "F" },
      ],
      span: 8,
      value: "male",
      validation: Yup.string().required('Gender is required'),
    },
  ];

const PersonalDetail = withFormik({
  mapPropsToValues: () => buildInitialValues(settingsPersonalDetailSchema({
  })),
  validationSchema: buildValidationSchema(settingsPersonalDetailSchema({
  })),
  enableReinitialize: true,
  validateOnChange: true,
  validateOnMount: true
})((props) => {
  const { translate } = useTranslation();
  const navigate = useNavigate();
  const { language } = useTranslation();

  const isRtl = language.dir === "rtl";
  const [isEditing, setIsEditing] = useState({
    email: false,
  });
  const [initialDataLoading, setInitialDataLoading] = useState(true)
  const [nationalities, setNationalities] = useState([]);
  const [titleOptions, setTitleOptions] = useState([]);
  const [formValues, setFormValues] = useState({
    email: "",
    fullName: "",
    arabicFullName: "",
    id: "",
    idExpiryDate: "",
    phone: "",
    gregorianDOB: "",
    hijriDOB: "",
    title: "",
    nationality: "",
    gender: "",
  });
  const { getUser } = useAuthHook();
  const [getpersonalInfo] = useGetpersonalInfoMutation();
  const [updateUserEmail] = useUpdateUserEmailMutation();
  const [updateGeneralInfo] = useUpdateGeneralInfoMutation();

  const user = getUser();

  const toggleEdit = async (field) => {
    if (isEditing[field]) {
      if (field === "email") {
        try {
          if (!user) return;
          const requestBody = {
            id: String(user.IDB_USR_ID),
            email: formValues.email,
          };
          await updateUserEmail(requestBody).unwrap();
          toast.success("Email updated successfully.");
        } catch (error) {
          console.error("Failed to update email", error);
          toast.error("Failed to update email.");
        }
      }
    }

    setIsEditing((prevState) => ({
      ...prevState,
      [field]: !prevState[field],
    }));
  };

  const handleInputChange = (e, field) => {
    setFormValues((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
  };

  useEffect(() => {
    const fetchUser = async () => {
      try {
        if (!user) return;

        const res = await getpersonalInfo({
          id: String(user?.IDB_USR_ID),
        }).unwrap();
        setFormValues({
          email: res.result.EMAIL || "",
          fullName: `${res.result.FIRST_NAME} ${res.result.MIDDLE_NAME} ${res.result.LAST_NAME} ${res.result.FAMILIY_NAME}`,
          arabicFullName: `${res.result.FIRST_NAME_AR} ${res.result.MIDDLE_NAME_AR} ${res.result.LAST_NAME_AR} ${res.result.FAMILIY_NAME_AR}`,
          id: res.result.USER_ID || "",
          idExpiryDate: formatDateDDMMYYYY(res.result.ID_EXPIRY_DATE),
          phone: res.result.MOBILE || "",
          gregorianDOB: res.result.DOB || "",
          hijriDOB: res.result.DOB_HIJRI || "",
          title: res.result.TITLEID || "",
          nationality: res.result.NATIONALITYID || "",
          gender: res.result.GENDER || "",
        });
        if (res?.result) {
          const { TITLEID, NATIONALITYID, GENDER } = res.result;
          props.setValues((values) => ({
            ...values,
            title: TITLEID ? String(TITLEID) : null,
            nationality: NATIONALITYID ? String(NATIONALITYID) : null,
            gender: GENDER ? String(GENDER) : null,
          }));
        }

      } catch (error) {
        console.log("Error while fetching personal info", error);
        toast.error("Failed to fetch personal info.");
      }
    };

    if (!initialDataLoading)
      fetchUser();
  }, [initialDataLoading]);

  const navigateBack = () => {
    navigate(-1);
  };

  const initializeData = async (user) => {
    try {
      const nationalitiesPromise = fetch(
        `${env.API_ENDPOINT}lookup/nationalities?status=A`
      )
        .then((res) => res.json())
        .then((data) =>
          setNationalities(
            data.payload.rows
              .filter(nationality => {
                // Hide all nationalities if user is saudi and show only saudi arabia
                if (user && String(user.USER_ID)?.[0] === '1') {
                  return String(nationality.NATIONALITYID) === '904576';
                }
                else {
                  return true;
                }
              })
              .map((row) => ({
                label: row.NATIONALITY,
                labelAr: row.NATIONALITY_AR,
                value: String(row.NATIONALITYID),
              }))
          )
        )
        .catch(() => {
          toast.error("Failed to load nationalities.");
          setTimeout(() => {
            navigateBack()
          }, 1000)
        });

      const titleOptionsPromise = fetch(
        `${env.API_ENDPOINT}lookup/title?status=A`
      )
        .then((res) => res.json())
        .then((data) =>
          setTitleOptions(
            data.payload.rows.map((row) => ({
              label: row.TITLE,
              value: String(row.TITLEID),
              labelAr: row.TITLE_AR,
            }))
          )
        )
        .catch(() => {
          toast.error("Failed to load titles.");
          setTimeout(() => {
            navigateBack()
          }, 1000)
        });


      await Promise.all([
        nationalitiesPromise,
        titleOptionsPromise,
      ]);
    } finally {
      // Set initial loading state to false after all operations are complete
      setInitialDataLoading(false);
    }
  }

  useEffect(() => {
    if (user)
      initializeData(user)
  }, [])

  const schema = useMemo(
    () => {
      return settingsPersonalDetailSchema({
        nationalities,
        titleOptions,
      });
    },
    [nationalities, titleOptions]
  );

  const handleSave = async () => {
    const requiredFields = ["title", "nationality", "gender"];

    const allFieldsFilled = requiredFields.every((field) => props.values[field]);

    if (!allFieldsFilled) {
      toast.error(translate("VALIDATION.FILL_REQUIRED_FIELDS"));
      return;
    }

    try {
      const user = getUser();
      if (!user) return;

      const requestBody = {
        id: String(user.IDB_USR_ID),
        titleId: props.values.title,
        nationalityId: props.values.nationality,
        gender: props.values.gender,
      };

      // Call the mutation to update general info
      await updateGeneralInfo(requestBody).unwrap();
      toast.success("general info updated successfully.");
      setTimeout(() => {
        navigateBack()
      }, 1000)
    } catch (error) {
      console.error("Error updating general info", error);
      toast.error("Failed to update general info.");
    }
  };

  return (
    <div className="rounded-xl min-h-16 mb-4 px-4 py-8 bg-white items-center shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
      <div className="gap-3 flex items-center">
        <div
          className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10"
          onClick={navigateBack}
        >
          {isRtl ? (
            <ArrowRightOutlined className="text-black text-lg font-extrabold" />
          ) : (
            <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
          )}
        </div>
        <div className="text-[#161616] text-[22px] font-normal font-sans">
          {translate("SETTINGS.SETTINGS")}
        </div>
      </div>

      <div className="text-black text-2xl font-semibold mt-3 mb-3">
        {translate("SETTINGS.GENERAL_INFO")}
      </div>

      {/* Responsive Container */}
      <div className="mb-5 rounded-[20px] px-5">
        <div className="text-base font-semibold text-[#5F5F5F]">
          {translate("SETTINGS.CUSTOMER_DETAIL")}
        </div>

        {/* First Row: Full Name, Arabic Name, ID */}
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Full Name */}
          <div className={isRtl ? "text-end w-full" : ""}>
            <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
              Full Name
            </div>
            <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
              {formValues.fullName}
            </div>
          </div>

          {/* Arabic Full Name */}
          <div className="flex items-start md:flex-row flex-col">
            <div className="hidden md:block mt-1 h-8 border-l border-[#BCBDCE]"></div>
            <hr className="block mb-4 md:hidden w-full border-[#BCBDCE]" />
            <div className={isRtl ? "md:mr-2" : "md:ml-2 text-end w-full"}>
              <div className="text-sm font-normal text-[#5F5F5F]">
                الاسم الكامل
              </div>
              <div className="text-sm font-normal text-[#5F5F5F]">
                {formValues.arabicFullName}
              </div>
            </div>
          </div>
          {/* ID */}
          <div className="flex items-start md:flex-row flex-col">
            <div className="hidden md:block mt-1 h-8 border-l border-[#BCBDCE]"></div>
            <hr className="block mb-4 md:hidden w-full border-[#BCBDCE]" />
            <div className={isRtl ? "md:mr-2" : "md:ml-2"}>
              <div className="text-sm font-normal text-[#5F5F5F]">
                {translate("CREATE_REQUEST.ID")}
              </div>
              <div className="text-sm font-normal text-[#5F5F5F]">
                {formValues.id}
              </div>
            </div>
          </div>
        </div>

        <hr className="my-4 border-[#BCBDCE]" />

        {/* Second Row: ID Expiry Date, Phone, Email */}
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* ID Expiry Date */}
          <div className="flex items-start md:flex-row flex-col">
            <div>
              <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
                {translate("CREATE_REQUEST.ID_Expiry_Date")}
              </div>
              <div className="font-calibri text-sm font-normal text-black">
                {formValues.idExpiryDate}
              </div>
            </div>
          </div>

          {/* Phone */}
          <div className="flex items-start md:flex-row flex-col">
            <div className="hidden md:block mt-1 h-8 border-l border-[#BCBDCE]"></div>
            <hr className="block mb-4 md:hidden w-full border-[#BCBDCE]" />
            <div className={isRtl ? "md:mr-2" : "md:ml-2"}>
              <div className="text-sm font-normal text-[#5F5F5F]">
                {translate("CREATE_REQUEST.Phone")}
              </div>
              <div className="text-sm font-normal text-[#5F5F5F]">
                {formValues.phone}
              </div>
            </div>
          </div>

          {/* Email */}
          <div className="flex items-start md:flex-row flex-col">
            <div className="hidden md:block mt-1 h-8 border-l border-[#BCBDCE]"></div>
            <hr className="block mb-4 md:hidden w-full border-[#BCBDCE]" />
            <div className="flex justify-between w-full">
              {/* Flex container to position text and icon */}
              <div
                className={isRtl ? "md:mr-2" : "md:ml-2"}
                style={{ width: "100%" }}
              >
                {isEditing.email ? (
                  <input
                    type="text"
                    value={formValues.email}
                    onChange={(e) => handleInputChange(e, "email")}
                    className="border border-gray-300 p-2 rounded-xl w-full text-gray-400 outline-none focus:ring-0"
                  // Make the input take over the entire space (label and value)
                  />
                ) : (
                  <div>
                    <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
                      {translate("CREATE_REQUEST.Email")}
                    </div>
                    <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
                      {formValues.email}
                    </div>
                  </div>
                )}
              </div>
              {isEditing.email ? (
                <CheckOutlined
                  className={`text-[#36B289] ${isRtl ? "mr-2" : "ml-2"}`}
                  onClick={() => toggleEdit("email")}
                />
              ) : (
                <EditOutlined
                  className={`text-gray-500 ${isRtl ? "mr-2" : "ml-2"}`}
                  onClick={() => toggleEdit("email")}
                />
              )}
            </div>
          </div>
        </div>

        <hr className="my-4 border-[#BCBDCE]" />

        {/* Third Row: Gregorian Date, Hijri Date */}
        <div className="mt-2 grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-4">
          {/* Gregorian Date */}
          <div>
            <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
              {translate("SETTINGS.GEORGIAN_DOB")}
            </div>
            <div className="font-calibri text-sm font-normal text-[#5F5F5F]">
              {formValues.gregorianDOB}
            </div>
          </div>

          {/* Hijri Date */}
          <div className="flex items-start md:flex-row flex-col">
            <div className="hidden md:block mt-1 h-8 border-l border-[#BCBDCE]"></div>
            <hr className="block mb-4 md:hidden w-full border-[#BCBDCE]" />
            <div className={isRtl ? "md:mr-2" : "md:ml-2"}>
              <div className="text-sm font-normal text-[#5F5F5F]">
                {translate("SETTINGS.HIJRI_DOB")}
              </div>
              <div className="text-sm font-normal text-[#5F5F5F]">
                {formValues.hijriDOB}
              </div>
            </div>
          </div>
        </div>

        <hr className="my-4 border-[#BCBDCE]" />

        <div className="mt-4">
          <FormDrawer schema={{
            fields: schema,
          }} />
        </div>
      </div>
      <div className="flex items-center justify-end gap-2">
        <Button
          onClick={navigateBack}
          color="primary"
          mode="outline"
          className="rounded-xl h-12 w-32"
        >
          {translate("CREATE_REQUEST.CANCEL")}
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          className="rounded-xl h-12 w-64"
        >
          {translate("SETTINGS.SAVE")}
        </Button>
      </div>
    </div>
  );
});

export default PersonalDetail;