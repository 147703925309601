import { Card } from 'antd';
import { useNavigate, useParams } from 'react-router-dom';
import { Button } from 'shared/components/Button';
import routes from 'shared/constants/routes';
import { useTranslation } from "../../../contexts/TranslationContext";

export default function SuccessProvider() {

  const navigate = useNavigate();
  const { applId } = useParams()
  const { translate } = useTranslation();

  return (
    <div className="mt-8 flex items-center justify-center">
      <Card className="w-96">
        <div className="text-center">
          <h2 className="font-bold text-xl mb-4 text-green-500 uppercase">{translate('CREATE_REQUEST.GREAT')}</h2>
          <div className="mb-4">
            <div className="font-bold uppercase">{translate('CREATE_REQUEST.YOUR_REQUEST')}</div>
            <div>{translate('CREATE_REQUEST.SUBMITTED_FOR_REVIEW')}</div>
          </div>
        </div>
        <div className="mb-4">{translate('CREATE_REQUEST.THANKS_FOR_APPLYING')}</div>
        <div className="mb-4 rounded-lg border border-gray-200 px-2 py-2">
          <div className="font-bold">{translate('CREATE_REQUEST.APP_NO')}</div>
          <div>{applId}</div>
        </div>
        <Button
          onClick={() => {
            navigate(`/${routes.APP_ROOT}/${routes.HOME}`);
          }}
          color="primary"
          className="mb-4 rounded-lg font-bold" block>{translate('CREATE_REQUEST.DONE')}</Button>
        <div className="text-center">
          <div>{translate('CREATE_REQUEST.WE_WILL_GET_BACK')}</div>
          <div>{translate('CREATE_REQUEST.KINDLY_MESSAGE')}</div>
          <div>{translate('CREATE_REQUEST.FURTHER_ASSISTANCE')}</div>
        </div>
      </Card>
    </div>
  );
}
