import env from "shared/constants/env";
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";

export const lookupApi = createApi({
  reducerPath: "lookupApi",
  baseQuery: fetchBaseQuery({ baseUrl: env.API_ENDPOINT }),
  endpoints: (builder) => ({
    getBusinessProvider: builder.query({
      query: () => "lookup/providers?status=A&REF_FLAG=Y&SRC_FLAG=N",
      transformResponse: (response) => {
        if (!response || !response.payload) {
          console.error("Invalid response structure:", response);
          return [];
        }
        return Object.values(response.payload) || [];
      },
    }),
    getBusinessProviderAlt: builder.query({
      query: ({id, queryParams}) => `lookup/providers?status=A&REF_FLAG=N&SRC_FLAG=Y&BPDESCRIPTION3=${id}${queryParams}`,
      transformResponse: (response) => {
        if (!response || !response.payload) {
          console.error("Invalid response structure:", response);
          return [];
        }
        return Object.values(response.payload) || [];
      },
    }),
  }),
});

export const {
  useGetBusinessProviderQuery,
  useLazyGetBusinessProviderAltQuery,
} = lookupApi;
