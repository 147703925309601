import {
  ArrowLeftOutlined,
  ArrowRightOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import {
  FormDrawer,
  buildInitialValues,
  buildValidationSchema,
} from "Components";
import { withFormik } from "formik";
import useAuthHook from "hooks/useAuthHook";
import {
  employmentDetailsSchema,
  financialDetailsSchema,
} from "pages/protected/CreateRequest/schemas";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import {
  useGetEligibilityMutation,
  useUpdateEligibilityMutation,
} from "services/auth";
import { Button } from "shared/components/Button";
import env from "shared/constants/env";
import routes, { appRoute } from "shared/constants/routes";
import formatDateDDMMYYYY from "utilities/formatDate";
import { useTranslation } from "../../../contexts/TranslationContext";

const Eligibility = withFormik({
  mapPropsToValues: () => ({
    ...buildInitialValues([
      ...financialDetailsSchema({ hasAdditionalIncome: true }),
      ...employmentDetailsSchema({
        hideRealBenificiaryName: true, // Set default value
        employmentTypes: [],
        residentStatuses: [],
        educationLevels: [],
        employmentSectors: [],
        relatedParty: [],
				occupations: []
      }),
    ]),
  }),
  validationSchema: buildValidationSchema([
    ...financialDetailsSchema({
      hasAdditionalIncome: true,
    }),
    ...employmentDetailsSchema({
      hideRealBenificiaryName: true, // Set default value
      employmentTypes: [],
      residentStatuses: [],
      educationLevels: [],
      employmentSectors: [],
      relatedParty: [],
      occupations: []
    }),
  ]),
  enableReinitialize: true,
  validateOnChange: true,
  validateOnMount: true,
})((props) => {
  const { translate } = useTranslation();
  const { language } = useTranslation();
  const navigate = useNavigate();
  const isRtl = language.dir === "rtl";
  const [initialDataLoading, setInitialDataLoading] = useState(true);
  const [employmentTypes, setEmploymentTypes] = useState([]);
  const [residentStatuses, setResidentStatuses] = useState([]);
  const [educationLevels, setEducationLevels] = useState([]);
  const [employmentSectors, setEmploymentSectors] = useState([]);
  const [relatedParty, setRelatedParty] = useState([]);
  const [occupations, setOccupations] = useState([]);
  const { getUser } = useAuthHook();
  const [getEligibility] = useGetEligibilityMutation();
  const [lookupsLoaded, setLookupsLoaded] = useState(false);
  const [updateEligibility] = useUpdateEligibilityMutation();

  const navigateBack = () => {
    navigate(-1);
  };

  // Calculate total expenses dynamically
  const calculateTotalExpenses = () => {
    const {
      foodExpenses,
      housingExpenses,
      domesticWorkers,
      educationExpenses,
      healthCareExpenses,
      commAndTransExpenses,
      insuranceExpenses,
      anyExpectedFutureExpense,
    } = props.values;

    return (
      Number(foodExpenses || 0) +
      Number(housingExpenses || 0) +
      Number(domesticWorkers || 0) +
      Number(educationExpenses || 0) +
      Number(healthCareExpenses || 0) +
      Number(commAndTransExpenses || 0) +
      Number(insuranceExpenses || 0) +
      Number(anyExpectedFutureExpense || 0)
    );
  };

  // Update total expenses whenever any individual expense changes
  useEffect(() => {
    const newTotalExpenses = calculateTotalExpenses();
    props.setFieldValue("totalExpenses", newTotalExpenses);
  }, [
    props.values.foodExpenses,
    props.values.housingExpenses,
    props.values.domesticWorkers,
    props.values.educationExpenses,
    props.values.healthCareExpenses,
    props.values.commAndTransExpenses,
    props.values.insuranceExpenses,
    props.values.anyExpectedFutureExpense,
  ]);

  useEffect(() => {
    // Fetch dropdown options first
    const fetchOptions = async () => {
      try {
        const [
          employmentTypesRes,
          educationLevelRes,
          residentStatusRes,
          employmentSectorRes,
          relatedPartyRes,
          occupationRes,
        ] = await Promise.all([
          fetch(`${env.API_ENDPOINT}lookup/employment-categories?status=A`),
          fetch(`${env.API_ENDPOINT}lookup/education_level?status=A`),
          fetch(`${env.API_ENDPOINT}lookup/residential_status?status=A`),
          fetch(`${env.API_ENDPOINT}lookup/empl_sector?status=A`),
          fetch(`${env.API_ENDPOINT}lookup/related-party?status=A`),
          fetch(`${env.API_ENDPOINT}lookup/occupations?status=A`),
        ]);

        const employmentTypeData = await employmentTypesRes.json();
        const educationLevelData = await educationLevelRes.json();
        const residentStatusData = await residentStatusRes.json();
        const employmentSectorData = await employmentSectorRes.json();
        const relatedPartyData = await relatedPartyRes.json();
        const occupationData = await occupationRes.json();
        if (!employmentTypeData.payload || !educationLevelData.payload || !residentStatusData.payload || !employmentSectorData.payload || !relatedPartyData.payload || !occupationData.payload) {
          toast.error("Failed to load options.");
          navigateBack()
        }
        if (employmentTypeData.payload?.rows) {
          setEmploymentTypes(
            employmentTypeData.payload.rows.map((types) => ({
              value: String(types.EMPCATGID),
              label: types.EMPCATGNAME,
              labelAr: types.EMPCATGNAME_AR,
            }))
          );
        }

        if (educationLevelData.payload?.rows) {
          setEducationLevels(
            educationLevelData.payload.rows.map((levels) => ({
              value: String(levels.QUALIFICATIONID),
              label: levels.QUALIFICATION,
              labelAr: levels.QUALIFICATION_AR,
            }))
          );
        }

        if (residentStatusData.payload?.rows) {
          setResidentStatuses(
            residentStatusData.payload.rows.map((status) => ({
              value: String(status.RESIDENTID),
              label: status.RESIDENT_STATUS,
              labelAr: status.RESIDENT_STATUS_AR,
            }))
          );
        }

        if (employmentSectorData.payload?.rows) {
          setEmploymentSectors(
            employmentSectorData.payload.rows.map((sector) => ({
              value: String(sector.SECTORID),
              label: sector.SECTOR,
              labelAr: sector.SECTOR_AR,
            }))
          );
        }

        if (relatedPartyData.payload?.rows) {
          setRelatedParty(
            relatedPartyData.payload.rows.map((party) => ({
              value: String(party.PARTYID),
              label: party.PARTY,
              labelAr: party.PARTY_AR,
            }))
          );
        }

        if(occupationData.payload?.rows){
          setOccupations(
            occupationData.payload.rows.map((occ) => ({
              value: String(occ.OCCUPATIONID),
              label: occ.OCCUPATIONNAME,
              labelAr: occ.OCCUPATIONNAME_AR,
            }))
          );
        }

        setLookupsLoaded(true);

      } catch (error) {
        toast.error("Failed to load options.");
        console.error("Error fetching options:", error);
        navigateBack()
      } finally {
        setInitialDataLoading(false);
      }
    };

    fetchOptions();
  }, []);

  useEffect(() => {
    if (lookupsLoaded) {
      const fetchEligibility = async () => {
        try {
          const user = getUser();
          if (!user) return;

          const res = await getEligibility({
            id: String(user?.IDB_USR_ID),
          }).unwrap();
          if (res?.result) {
            const {
              SECTORID,
              EMPCATID,
              QUALIFICATIONID,
              RESIDENTID,
              DATEOFJOINING,
              FOOD_EXPENSES,
              HOUSING_EXPENSES,
              DOMESTIC_WORKERS,
              EDUCATION_EXPENSES,
              HEALTHCARE_EXPENSES,
              COMM_TRANS_EXPENSES,
              INSURANCE_EXPENSES,
              FUTURE_EXPENSES,
              TOTAL_EXPENSES,
              PEP,
              BENEFICIARY_BOOLEAN,
              BENEFICIARY_NAME,
              RELATED_PARTY_BOOLEAN,
              PARTYID,
              OCCUPATIONID
            } = res.result;

            // Populate dropdowns
            if (EMPCATID) {
              setEmploymentTypes((prevOptions) =>
                prevOptions.map((option) =>
                  option.value === String(EMPCATID)
                    ? { ...option, selected: true }
                    : option
                )
              );
            }
            if (RESIDENTID) {
              setResidentStatuses((prevOptions) =>
                prevOptions.map((option) =>
                  option.value === String(RESIDENTID)
                    ? { ...option, selected: true }
                    : option
                )
              );
            }
            if (QUALIFICATIONID) {
              setEducationLevels((prevOptions) =>
                prevOptions.map((option) =>
                  option.value === String(QUALIFICATIONID)
                    ? { ...option, selected: true }
                    : option
                )
              );
            }
            if (SECTORID) {
              setEmploymentSectors((prevOptions) =>
                prevOptions.map((option) =>
                  option.value === String(SECTORID)
                    ? { ...option, selected: true }
                    : option
                )
              );
            }

            if (OCCUPATIONID) {
              setOccupations((prevOptions) =>
                prevOptions.map((option) =>
                  option.value === String(OCCUPATIONID)
                    ? { ...option, selected: true }
                    : option
                )
              );
            }

            // Set Formik values with all eligibility data
            props.setValues((values) => ({
              ...values,
              sectorId: SECTORID ? String(SECTORID) : null,
              empCatId: EMPCATID ? String(EMPCATID) : null,
              qualificationId: QUALIFICATIONID ? String(QUALIFICATIONID) : null,
              p_residentId: RESIDENTID ? String(RESIDENTID) : null,
              dateOfJoining: DATEOFJOINING ? formatDateDDMMYYYY(DATEOFJOINING) : "",
              foodExpenses: FOOD_EXPENSES || 0,
              housingExpenses: HOUSING_EXPENSES || 0,
              domesticWorkers: DOMESTIC_WORKERS || 0,
              educationExpenses: EDUCATION_EXPENSES || 0,
              healthCareExpenses: HEALTHCARE_EXPENSES || 0,
              commAndTransExpenses: COMM_TRANS_EXPENSES || 0,
              insuranceExpenses: INSURANCE_EXPENSES || 0,
              anyExpectedFutureExpense: FUTURE_EXPENSES || 0,
              totalExpenses: TOTAL_EXPENSES,
              pep: PEP,
              beneficiary: BENEFICIARY_BOOLEAN,
              beneficiaryName: BENEFICIARY_NAME,
              relatedPartyBoolean: RELATED_PARTY_BOOLEAN,
              partyId: PARTYID ? String(PARTYID) : null,
              occupationId: OCCUPATIONID ? String(OCCUPATIONID) : null
            }));
            // Update hideRealBenificiaryName based on BENEFICIARY_BOOLEAN
          } else {
            props.setValues((values) => ({
              ...values,
              foodExpenses: 0,
              housingExpenses: 0,
              domesticWorkers: 0,
              educationExpenses: 0,
              healthCareExpenses: 0,
              commAndTransExpenses: 0,
              insuranceExpenses: 0,
              anyExpectedFutureExpense: 0,
              totalExpenses: 0,
            }));
          }
        } catch (error) {
          console.error("Error fetching eligibility:", error);
          toast.error("Failed to load eligibility data.");
        }
      };
      fetchEligibility();
    }
  }, [lookupsLoaded]);

  const handleSave = async () => {
    const requiredFields = [
      "empCatId",
      "qualificationId",
      "p_residentId",
      "sectorId",
      "dateOfJoining",
      "foodExpenses",
      "domesticWorkers",
      "educationExpenses",
      "housingExpenses",
      "healthCareExpenses",
      "commAndTransExpenses",
      "insuranceExpenses",
      "anyExpectedFutureExpense",
      "totalExpenses",
      "pep",
      "beneficiary",
      "relatedPartyBoolean",
      "occupationId"
    ];

    const emptyFields = requiredFields.filter(
      (field) => props.values[field] === "" || props.values[field] === undefined
    );

    if (emptyFields.length > 0) {
      toast.error(translate("VALIDATION.FILL_REQUIRED_FIELDS"));
      return;
    }

    if (
      !props.values.beneficiary &&
      (props.values?.beneficiaryName?.length > 50 || !props.values.beneficiaryName?.length)
    ) {
      toast.error(
        props.values?.beneficiaryName?.length > 50
          ? "Beneficiary name cannot exceed 50 characters."
          : "Please enter Real beneficiary name."
      );
      return;
    }

    if (props.values.relatedPartyBoolean && !props.values.partyId) {
      toast.error("Please select Position.");
      return;
    }

    try {
      const user = getUser();
      if (!user) return;

      const requestBody = {
        id: String(user.IDB_USR_ID),
        employmentType: props.values.empCatId,
        educationLevel: props.values.qualificationId,
        residentStatus: props.values.p_residentId,
        employmentSector: props.values.sectorId,
        joiningDate: props.values.dateOfJoining,
        foodExpenses: props.values.foodExpenses,
        domesticWorkers: props.values.domesticWorkers,
        educationExpenses: props.values.educationExpenses,
        housingExpenses: props.values.housingExpenses,
        healthcareExpenses: props.values.healthCareExpenses,
        commTransExpenses: props.values.commAndTransExpenses,
        insuranceExpenses: props.values.insuranceExpenses,
        futureExpenses: props.values.anyExpectedFutureExpense,
        totalExpenses: props.values.totalExpenses,
        pep: Boolean(props.values.pep),
        beneficiary: Boolean(props.values.beneficiary),
        beneficiaryName: props.values.beneficiary ? "" : props.values.beneficiaryName,
        relatedPartyBoolean: props.values.relatedPartyBoolean,
        partyId: props.values.relatedPartyBoolean ? props.values.partyId : null,
        occupationId: props.values.occupationId
      };

      // Call the mutation to update marital status
      await updateEligibility(requestBody).unwrap();
      toast.success("Eligibility updated successfully.");
      setTimeout(() => {
        navigateBack()
      }, 1000)
    } catch (error) {
      console.error("Error updating eligibility:", error);
      toast.error("Failed to update eligibility.");
    }
  };

  useEffect(() => {
    if (props.values?.beneficiary) {
      props.setValues((values) => ({
        ...values,
        beneficiaryName: "",
      }));
    }
  }, [props.values?.beneficiary]);

  useEffect(() => {
    if (!props.values?.relatedPartyBoolean) {
      props.setValues((values) => ({
        ...values,
        partyId: null,
      }));
    }
  }, [props.values?.relatedPartyBoolean]);

  const employmentSchemaFields = useMemo(() => {
    let hideRealBenificiaryName = true
    let hideRelatedParty = true
    if (props.values?.beneficiary === 0) { //enable realBenificiaryName if option is no
      hideRealBenificiaryName = false
    }
    if (props.values?.relatedPartyBoolean) { //enable relatedParty if option is yes
      hideRelatedParty = false
    }
    return employmentDetailsSchema({
      hideRealBenificiaryName,
      employmentTypes,
      residentStatuses,
      educationLevels,
      employmentSectors,
      relatedParty,
      hideRelatedParty,
      occupations,
    });
  }, [
    employmentTypes,
    residentStatuses,
    educationLevels,
    employmentSectors,
    relatedParty,
    occupations,
    props.values?.relatedPartyBoolean,
    props.values?.beneficiary,
  ]);

  const financialSchemaFields = useMemo(() => {
    return financialDetailsSchema({
      hasAdditionalIncome: props.values?.hasAdditionalIncome === "2",
      hasExistingLoan: props.values?.hasExistingLoan === "2",
    });
  }, [props.values?.hasAdditionalIncome, props.values?.hasExistingLoan]);

  if (initialDataLoading) {
    return (
      <div className="p-5 bg-white flex flex-col gap-4">
        <div className="flex gap-4">
          <LoadingOutlined />
          <span>{translate("CREATE_REQUEST.LOADING_EMPLOYMENT_DETAILS")}</span>
        </div>
      </div>
    );
  }

  return (
    <div className="rounded-xl min-h-16 mb-4 px-4 py-8 bg-white items-center shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
      <div className="gap-3 flex items-center">
        <div
          className="bg-[#F3EDDD] rounded-lg hover:bg-gray-100 transition duration-300 flex items-center justify-center w-10 h-10"
          onClick={navigateBack}
        >
          {isRtl ? (
            <ArrowRightOutlined className="text-black text-lg font-extrabold" />
          ) : (
            <ArrowLeftOutlined className="text-black text-lg font-extrabold" />
          )}
        </div>
        <div className="text-[#161616] text-[22px] font-normal font-sans">
          {translate("SETTINGS.SETTINGS")}
        </div>
      </div>
      <div className="text-black text-2xl font-semibold mt-3 mb-3">
        {translate("SETTINGS.ELIGIBILITY")}
      </div>
      {/* Employment Details Section */}
      <div className="text-[#262626] text-lg font-semibold my-3">
        {translate("CREATE_REQUEST.EMPLOYMENT_DETAILS")}
      </div>
      <div className="mb-4">
        <FormDrawer
          schema={{
            fields: employmentSchemaFields,
          }}
          values={props.values}
          onChange={props.handleChange}
        />
      </div>

      {/* Financial Details Section */}
      <div className="text-[#262626] text-lg font-semibold my-3">
        {translate("CREATE_REQUEST.FINANCIAL_DETAILS")}
      </div>
      <div className="mb-4">
        <FormDrawer
          schema={{
            fields: financialSchemaFields,
          }}
          values={props.values}
          onChange={props.handleChange}
        />
      </div>
      <div className="flex items-center justify-end gap-2">
        <Button
          onClick={navigateBack}
          color="primary"
          mode="outline"
          className="rounded-xl h-12 w-32"
        >
          {translate("CREATE_REQUEST.CANCEL")}
        </Button>
        <Button
          onClick={handleSave}
          color="primary"
          className="rounded-xl h-12 w-64"
        >
          {translate("SETTINGS.SAVE")}
        </Button>
      </div>
    </div>
  );
});

export default Eligibility;
