import { CheckOutlined, LeftOutlined, LoadingOutlined, RightOutlined } from "@ant-design/icons";
import { useTranslation } from "contexts/TranslationContext";
import useAuthHook from "hooks/useAuthHook";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import { useGetPreAppStatusQuery } from "services/auth";
import routes, { appRoute } from "shared/constants/routes";

export default function PreApp() {
  const { translate } = useTranslation();
  const { language } = useTranslation();
  const { getUser } = useAuthHook();

  const isRtl = language.dir === "rtl";
  const navigate = useNavigate();

  const user = getUser();
  const userId = user ? String(user.IDB_USR_ID) : null;

  const navigateTo = (route) => {
    navigate(appRoute(route));
  };

  const steps = [
    {
      id: '1',
      step: translate("SHARED.ONE"),
      title: translate("PRE.PERSONAL_DATA_HEADING"),
      description: translate("PRE.PERSONAL_DATA_DESCRIPTION"),
      route: routes.SETTING.PERSONAL_DETAIL,
    },
    {
      id: '2',
      step: translate("SHARED.TWO"),
      title: translate("PRE.ADDRESS_DTL_HEADING"),
      description: translate("PRE.ADDRESS_DTL_DESCRIPTION"),
      route: routes.SETTING.ADDRESS_DETAIL,
    },
    {
      id: '3',
      step: translate("SHARED.THREE"),
      title: translate("PRE.MARITAL_STATUS_HEADING"),
      description: translate("PRE.MARITAL_STATUS_DESCRIPTION"),
      route: routes.SETTING.MARITAL_STATUS,
    },
    {
      id: '4',
      step: translate("SHARED.FOUR"),
      title: translate("PRE.ELIGIBILITY_HEADING"),
      description: translate("PRE.ELIGIBILITY_DESCRIPTION"),
      route: routes.SETTING.ELIGIBILITY,
    },
  ];

  const { data, isLoading, isError, refetch } = useGetPreAppStatusQuery({ id: userId }, { skip: !userId, refetchOnMountOrArgChanges: true });

  if (isError) {
    toast.error("Failed to fetch pre app status. Please check network and API.");
  }

  useEffect(() => {
    if (data?.result?.completedSteps.length >= 4) {
      navigate(appRoute(routes.HOME));
    }
  }, [data])

  return (
    <div>
      <section className="mb-4">
        <h1 className="text-4xl text-gray-500 mb-2">
          <strong className="text-gray-700">{translate("PRE.WELCOME")}</strong>
        </h1>
        <div className="text-gray-500">
          {translate("PRE.DESCRIPTION")}
        </div>
      </section>

      {isLoading ? (
        <div className="p-5 bg-white flex flex-col gap-4">
          <div className="flex gap-4">
            <LoadingOutlined />
            <span>{translate("LOADING")}</span>
          </div>
        </div>
      ) : (
        <section>
          <div className="bg-white rounded border p-6">
            <div className="mb-4">
              <h2 className="text-2xl font-bold">
                {translate("PRE.STARTED")}
              </h2>
            </div>
            <div className="space-y-4">
              {steps.map((step) => (
                <div
                  key={step.id}
                  className="flex items-center justify-between border rounded-md p-4 hover:shadow-md cursor-pointer"
                  onClick={() => navigateTo(step.route)}
                >
                  <div className="flex items-center">
                    <div
                      className={`flex items-center justify-center w-10 h-10 rounded-lg font-bold ${data?.result?.completedSteps.includes(step.id)
                        ? "bg-green-100 text-green-600"
                        : "bg-gray-200 text-gray-700"
                        } ${isRtl ? "ml-4" : "mr-4"}`}
                    >
                      {data?.result?.completedSteps.includes(step.id) ? (
                        <CheckOutlined />
                      ) : (
                        step.step
                      )}
                    </div>
                    <div>
                      <h3 className="text-lg font-semibold text-gray-800">
                        {step.title}
                      </h3>
                      <p className="text-sm text-gray-500">{step.description}</p>
                    </div>
                  </div>
                  <div className="text-gray-400">
                    {!isRtl ? <RightOutlined /> : <LeftOutlined />}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </section>
      )}
    </div>
  );
}
