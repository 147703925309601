import { FormDrawer, buildInitialValues, buildValidationSchema } from 'Components';
import { LoadingOutlined, UploadOutlined } from "@ant-design/icons";
import { API_URL } from 'constants';
import { withFormik } from 'formik';
import { Upload, Typography } from 'antd';
import { useEffect, useMemo, useState } from 'react';
import { toast } from 'react-toastify';
import { PATCH_LOAN_DETAILS, SET_ACTIVE_STEP_INDEX, SET_STEP_VALIDITY, useCreateRequestContext, PATCH_DOCUMENT_DETAILS } from './CreateRequestContext';
import { loanDetailsSchema } from './schemas';
import * as LOCAL_STORAGE_KEYS from 'shared/constants/localStorageKeys';
import { useTranslation } from "../../../contexts/TranslationContext";
import env from 'shared/constants/env';

const { Dragger } = Upload;
const { Title, Text } = Typography;


const LoanDetails = withFormik({
  mapPropsToValues: () => buildInitialValues(loanDetailsSchema({
    hideRequestedLoanAmount: true,
  })),
  validationSchema: buildValidationSchema(loanDetailsSchema({
    hideRequestedLoanAmount: true,
  })),
  validateOnMount: true,
  validateOnChange: true,
  validateOnBlur: true,
  enableReinitialize: true
})(props => {
  const { state, dispatch } = useCreateRequestContext();
  const [productsList, setProductsList] = useState([]);
  const [subProductsList, setSubProductsList] = useState([]);
  const { translate } = useTranslation();
  const [tenureOptions, setTenureOptions] = useState([]);
  const [initialDataLoading, setInitialDataLoading] = useState(true)
  const [uploadFile, setUploadFile] = useState(null);
  const [fileList, setFileList] = useState([]);



  const userId = JSON.parse(
    localStorage.getItem(LOCAL_STORAGE_KEYS.USER)
  )?.USER_ID;

  useEffect(() => {
    dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: 0 });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SET_STEP_VALIDITY,
      payload: {
        stepIndex: 0,
        valid: props.isValid,
      },
    });
  }, [dispatch, props.isValid]);

  useEffect(() => {
    dispatch({
      type: PATCH_LOAN_DETAILS,
      payload: {
        ...props.values,
        isTawarooq: props.values?.typeOfLoan === '328',
        isAutofacility: props.values?.subproductId === '250' || props.values?.subproductId === '331'
      },
    });
  }, [dispatch, props.values]);

  useEffect(() => {
    fetch(`${env.API_ENDPOINT}lookup/products?status=A`)
      .then(res => res.json())
      .then(res => {
        setProductsList(
          res.payload.rows
            .filter(product => {
              // Hide Tawarooq if user is non-Saudi.
              if ((userId && String(userId)?.[0] === '2') && product.PRODUCT_CODE === 'APL') {
                return false;
              } else {
                return true;
              }
            })
            .map(product => ({
              label: product.PRODUCT_NAME,
              value: JSON.stringify(product),
              labelAr: product.PRODUCT_NAME_AR
            }))
        );
      })
      .catch(() => {
        toast.error('Failed to load products.');
      }).finally(() => {
        setInitialDataLoading(false)
      })
  }, []);

  useEffect(
    () => {
      let product = props.values?.productId;
      if (!product) {
        return;
      }
      props.setValues((prev) => ({ ...prev, subproductId: null, request_amount: null, IBAN: null }))
      product = JSON.parse(product);
      setSubProductsList([]);
      fetch(`${env.API_ENDPOINT}lookup/sub-products?status=A&productCode=${product.PRODUCT_CODE}`)
        .then(res => res.json())
        .then(res => {
          setSubProductsList(res.payload.rows.map(row => ({
            label: row.SUBPRODUCT_NAME,
            value: String(row.SUBPRODUCTID),
            labelAr: String(row.SUBPRODUCT_NAME_AR),
          })));
        })
        .catch(() => {
          toast.error('Failed to load sub-products.');
        });
    }, [props.values?.productId]);

  useEffect(() => {
    if (String(props.values?.tenure).length > 2) {
      props.setFieldValue('tenure', String(props.values?.tenure).substring(0, 2));
    }
  }, [props.values?.tenure]); // eslint-disable-line

  useEffect(() => {
    props.setValues({
      ...state.loanDetails,
      // tenure: state.loanDetails.tenure ? state.loanDetails.tenure : '12'
      tenure: state.loanDetails.tenure ? state.loanDetails.tenure : ''
    })
  }, [])


  useEffect(() => {  //this for tenure options updation based on requested loan amount
    let product = props.values?.productId;
    if (!product) {
      return;
    }
    product = JSON.parse(product);

    const amount = props.values?.request_amount;
    let tenureOpt = [
      { value: "6", label: "6" },
      { value: "12", label: "12" },
      { value: "24", label: "24" },
      { value: "36", label: "36" },
      { value: "48", label: "48" },
      { value: "60", label: "60" },
    ];

    if (product.PRODUCTID === 328) { //if tawarook then options validation
      if (amount <= 10000 && amount >= 3000) {
        tenureOpt = [
          { value: "6", label: "6" },
          { value: "12", label: "12" },
        ];
      } else if (amount >= 10001 && amount <= 25000) {
        tenureOpt = [
          { value: "6", label: "6" },
          { value: "12", label: "12" },
          { value: "24", label: "24" },
          { value: "36", label: "36" },
        ];
      } else if (amount >= 25001 && amount <= 500000) {
        tenureOpt = [
          { value: "6", label: "6" },
          { value: "12", label: "12" },
          { value: "24", label: "24" },
          { value: "36", label: "36" },
          { value: "48", label: "48" },
          { value: "60", label: "60" },
        ];
      }
      else if (amount > 500000 || amount < 3000) {
        tenureOpt = [];
      }
    }

    // Update the tenure options in the schema
    setTenureOptions(tenureOpt);

    // Reset the selected tenure if it's no longer valid
    let tenureSelectionCheck = tenureOpt.find(opt => opt.value === props.values?.tenure)
    if (tenureSelectionCheck === undefined && props.values?.tenure !== "") {
      props.setValues((prev) => ({
        ...prev,
        tenure: ""
      }))
      // setTenureOptions([])
    }
  }, [props.values?.request_amount, props.values?.productId]);



  const schemaFields = useMemo(
    () => {
      let disableSubProduct = true;  //if no product selected then subProduct is disabled
      let hideRequestedLoanAmount = true;

      if (props.values?.productId) {
        disableSubProduct = false;
        let prod = JSON.parse(props.values?.productId);
        hideRequestedLoanAmount = prod.PRODUCTID === 217;
      }

      return loanDetailsSchema({
        hideRequestedLoanAmount: hideRequestedLoanAmount,
        disableSubProduct: disableSubProduct,
        productsList,
        subProductsList,
        tenureOptions
      })
    },
    [productsList, subProductsList, props.values?.productId, tenureOptions]
  );

  if (initialDataLoading) {
    return (
      <div className='p-5 bg-white flex flex-col gap-4'>
        <div className='flex gap-4'>
          <LoadingOutlined />
          <span>{translate("CREATE_REQUEST.LOADING_LOAN_DETAILS")}</span>
        </div>
      </div>
    );
  }

  const beforeUpload = (file) => {
    const isJpgOrPngOrPdf =
      file.type === "image/jpeg" ||
      file.type === "image/png" ||
      file.type === "image/jpg" ||
      file.type === "application/pdf";
    if (!isJpgOrPngOrPdf) {
      toast.error(translate("SETTINGS.ERROR_FILE_TYPE"));
      return true;
    }

    const isLt3M = file.size / 1024 / 1024 < 3;
    if (!isLt3M) {
      toast.error(translate("SETTINGS.ERROR_FILE_SIZE_3MB"));
      return true;
    }

    const fileName = file.name;
    const multipleExtensionsPattern = /(\.[^.\s]{2,4}){2,}$/;
    if (multipleExtensionsPattern.test(fileName)) {
      toast.error(translate("SETTINGS.ERROR_FILE_MULTIPLE_EXT"));
      return true;
    }
    const invalidFileNamePattern = /^[^.]+\.[^.]+\..+\.[^.]+$/;

    if (invalidFileNamePattern.test(fileName)) {
      toast.error(translate("SETTINGS.ERROR_FILE_MULTIPLE_EXT"));
      return true;
    }

    if (fileName.toLowerCase().includes('contract')) {
      toast.error(translate("SETTINGS.ERROR_FILE_NAME_CONTRACT"));
      return true
    }
    setUploadFile(file)
    return false;
  };

  const dragAndDropProps = {
    name: 'file',
    multiple: true,
    maxCount: 3,
    accept: "image/png,image/jpeg,image/jpg,application/pdf",
    beforeUpload,
    fileList,
    onChange(info) {
      if (info.file.status === 'error' || info.file.status === 'removed') {
        setFileList(prev => prev.filter(i => i.uid !== info.file.uid))
        return
      }
      dispatch({ type: PATCH_DOCUMENT_DETAILS, payload: [...fileList, info.file] });
      setFileList(prev => [...prev, info.file])

    },
    onDrop(e) {
      setFileList([e.dataTransfer.files])
    },
  };

  const handleRemove = (e) => {
    let updateFiles = fileList.filter(i => i.uid !== e.uid)
    // console.log('hi', updateFiles)
    dispatch({ type: PATCH_DOCUMENT_DETAILS, payload: updateFiles });
    setFileList(prev => prev.filter(i => i.uid !== e.uid));
    setUploadFile(null)
  }


  return (
    <>
      <h2 className="font-bold text-xl mb-4">{translate('CREATE_REQUEST.LOAN_DETAILS')}</h2>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 mb-4">
        <FormDrawer schema={{
          fields: schemaFields,
        }} />
        <div className='my-4'>
          <label className="block text-gray-600 font-medium mb-2">{translate("SETTINGS.DOCUMENT")}</label>
          <div className="border-dashed border-2 border-gray-300 rounded-md p-6 mb-4 text-center">
            <Dragger
              {...dragAndDropProps} onRemove={e => handleRemove(e)}
              className="!border-none"
            >
              <UploadOutlined className="text-3xl text-teal-600 mb-2" />
              <p className="text-teal-600 font-medium">{translate("SETTINGS.DOCUMENTS_DRAG_DROP_HEADING")}</p>
              <br />
              <Text type="secondary" className="text-xs">
                {translate("SETTINGS.DOCUMENTS_DRAG_DROP_TEXT")}
              </Text>
            </Dragger>
          </div>
        </div>
      </div>
    </>
  );
});

export default LoanDetails;
