import * as Yup from "yup";

export const partnersSchema = ({ hideRequestedLoanAmount = true }) => [
  {
    type: "select",
    key: "tenure",
    label: "Tenure",
    required: true,
    options: [
      { value: "4", label: "4" },
      { value: "8", label: "8" },
      { value: "12", label: "12" },
    ],
    value: 4,
    span: 20,
    validation: Yup.string()
      .required("CANNOT_BE_EMPTY")
    // .when('request_amount', (request_amount, schema) => {
    //   if (request_amount >= 2500 && request_amount <= 5000) {
    //     return schema.test(
    //       'max1',
    //       'MAX_12_MONTHS',
    //       value => { return parseInt(value, 10) <= 4 }
    //     );
    //   } else if (request_amount >= 5001 && request_amount <= 20000) {
    //     return schema.test(
    //       'max2',
    //       'MAX_36_MONTHS',
    //       value => parseInt(value, 10) <= 8
    //     );
    //   } else if (request_amount >= 20001) {
    //     return schema.test(
    //       'max3',
    //       'MAX_60_MONTHS',
    //       value => parseInt(value, 10) <= 12
    //     );
    //   }
    //   return schema; // default case if none of the conditions are met
    // }),
  },
  // { type: "number", key: "IBAN", label: "IBAN", span: 20, translation: 'REQUEST_DETAILS.IBAN' },
  {
    type: "number",
    key: "request_amount",
    label: "Requested_loan_amount",
    span: 20,
    required: !hideRequestedLoanAmount,
    disabled: hideRequestedLoanAmount,
    validation: Yup.number()
      .when('productId', (productId, schema) => {
        // const parsed = productId[0] ? JSON.parse(productId[0]) : null;
        // if (parsed && parsed.PRODUCTID === 328) {
        return schema.required("CANNOT_BE_EMPTY")
          .integer("MUST_BE_INTEGER")
          .min(2000, "MUST_BE_ATLEAST_2000")
          .max(50000, "CANNOT_EXCEED_50000")
        // }
        // return schema.notRequired();
      }),
  },
  {
    type: "text",
    key: "salesRef",
    label: "SalesRef",
    span: 20,
    required: false,
    validation: Yup.string()
      .matches(/^\d{8}$/, "MUST_BE_8_DIGITS")
    // .required("CANNOT_BE_EMPTY")
  },
];