import { SearchOutlined } from '@ant-design/icons';
import { Input } from 'antd';
import { useTranslation } from "contexts/TranslationContext";

const SearchField = ({ onChange, style }) => {
  const { translate } = useTranslation();
  return (
    <Input
      placeholder={translate('SHARED.SEARCH')}
      onChange={onChange}
      prefix={<SearchOutlined />}
      style={{ marginBottom: '10px', width: '300px', height: '40px', ...style }}
    />
  )
}

export default SearchField