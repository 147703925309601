import { Button, Card, Col, Row, Typography } from 'antd';
import ProviderLogo from "assets/svg/providerLogo.svg";
import { useTranslation } from "contexts/TranslationContext";
import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useLazyGetBusinessProviderAltQuery } from "services/lookup";
import BackButton from 'shared/components/BackButton/BackButton';
import NoData from 'shared/components/NoData';
import SearchField from 'shared/components/SearchField/SearchField';
import routes, { appRoute } from 'shared/constants/routes';

const { Title, Text } = Typography;

const tabs = [
  { key: '1', label: 'All' }
];

const styles = {
  tabButton: (isActive) => ({
    height: '50px',
    borderRadius: '8px',
    marginRight: '10px',
    borderColor: isActive ? 'transparent' : '#d9d9d9',
    backgroundColor: isActive ? '#FFD700' : '#fff',
    color: isActive ? '#000' : '#333',
    fontWeight: '500',
    padding: '6px 36px',
    minWidth: '80px',
    textAlign: 'center',
    boxShadow: isActive ? '0 2px 4px rgba(0, 0, 0, 0.1)' : 'none',
  }),
};

const Partners = () => {
  const navigate = useNavigate();
  const { translate } = useTranslation();
  const { id } = useParams()
  const [fetchProviders, { data: partners }] =
    useLazyGetBusinessProviderAltQuery();

  const handleFetchProviders = async (queryParams = '') => {
    fetchProviders({ id, queryParams }); // Trigger the API call
  };

  const onSearch = async (e) => {
    const { value } = e.target;
    const data = await handleFetchProviders(`&like_BPTYPE=${encodeURIComponent(value.toUpperCase())}`);
  };

  const handleTabClick = async (label) => {
    const data = await handleFetchProviders(`&label=${encodeURIComponent(label)}`);
  };

  useEffect(() => {
    const loadInitialData = async () => {
      const data = await handleFetchProviders();
    };
    loadInitialData();
  }, []);

  return (
    <div className="rounded-xl min-h-16 mb-4 px-4 py-4 bg-white items-center shadow-[0_4px_10px_-2px_rgba(0,0,0,0.1),0_-4px_10px_-2px_rgba(0,0,0,0.05)]">
      <div className="p-2">
        <BackButton onClick={() => navigate(-1)} />
        <Title level={4} style={{ marginTop: 10 }}>{translate('PROVIDERS.PROVIDERS')}</Title>
        <SearchField onChange={onSearch} />
        <div className="custom-tabs">
          {tabs.map((tab, index) => (
            <Button
              key={tab.key}
              type={index === 0 ? 'primary' : 'default'}
              style={styles.tabButton(index === 0)}
              onClick={() => handleTabClick(tab.label)}
            >
              {tab.label}
            </Button>
          ))}
        </div>
        {partners?.length > 0 ? (
          <Row gutter={[16, 16]} style={{ marginTop: '20px' }}>
            {partners.map((partner) => (
              <Col xs={24} sm={12} md={8} lg={6} key={partner.id}>
                <Card
                  onClick={() => navigate(appRoute(`${routes.PARTNERS}/${partner.BPDESCRIPTION3}/${partner.BPTYPEID}/application`))}
                  hoverable
                  cover={
                    <img
                      src={partner.img || ProviderLogo}
                      alt={partner.BPTYPE}
                      style={{ width: 200, height: 100, margin: '10px auto' }}
                    />
                  }
                  style={{ textAlign: 'center' }}
                >
                  <Title level={5}>{partner.BPTYPE}</Title>
                  <hr />
                  <Text type="secondary">{partner.BPDESCRIPTION}</Text>
                </Card>
              </Col>
            ))}
          </Row>
        ) : (
          <NoData />
        )}
      </div>
    </div>
  );
};

export default Partners;
