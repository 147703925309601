import { buildInitialValues, buildValidationSchema } from 'Components';
import { Checkbox, InputNumber } from 'antd';
import { withFormik } from 'formik';
import { useEffect, useState } from 'react';
import { PATCH_GUARANTOR_DETAILS, SET_ACTIVE_STEP_INDEX, SET_STEP_VALIDITY, useCreateRequestContext } from './CreateRequestContext';
import { newGuarantorSchema } from './schemas';
import { useTranslation } from "../../../contexts/TranslationContext";

const Guarantors = withFormik({
  mapPropsToValues: () => buildInitialValues(newGuarantorSchema),
  validationSchema: buildValidationSchema(newGuarantorSchema),
})(props => {
  const [haveSalesPersonRef, setHaveSalesPersonRef] = useState(false);
  const [salesPersonRef, setSalesPersonRef] = useState(null);
  const { translate } = useTranslation();


  const { state, dispatch } = useCreateRequestContext();

  useEffect(() => {
    dispatch({ type: SET_ACTIVE_STEP_INDEX, payload: 3 });
  }, [dispatch]);

  useEffect(() => {
    dispatch({
      type: SET_STEP_VALIDITY,
      payload: {
        stepIndex: 3,
        valid: props.isValid,
      },
    });
  }, [dispatch, props.isValid]);

  useEffect(() => {
    if (state.salesPersonRefDetails?.salesRef) {
      setHaveSalesPersonRef(true)
      setSalesPersonRef(state.salesPersonRefDetails?.salesRef)
    }
  }, [])


  useEffect(() => {
    dispatch({
      type: PATCH_GUARANTOR_DETAILS,
      payload: props.values,
    });
  }, [dispatch, props.values]);

  return (
    <>
      <h2 className="font-bold text-xl mb-4">{translate('CREATE_REQUEST.SALES_REFERENCE')}</h2>

      <Checkbox
        className="mb-4"
        checked={haveSalesPersonRef}
        onChange={(e) => setHaveSalesPersonRef(e.target.checked)}>
        {translate('CREATE_REQUEST.DO_YOU_SALE_REFERENCE')}
      </Checkbox>

      {
        haveSalesPersonRef && (
          <div className="mb-4">
            <InputNumber
              className="mb-2 block w-full"
              size="large"
              maxLength={8}
              placeholder={translate('CREATE_REQUEST.SALES_PERSON_REF')}
              value={salesPersonRef}
              onChange={e => {
                dispatch({
                  type: PATCH_GUARANTOR_DETAILS,
                  payload: {
                    salesRef: String(e),
                  },
                });
                setSalesPersonRef(e);
              }}
              required={haveSalesPersonRef} />
            <div className="text-xs">
              {translate('CREATE_REQUEST.EXACTLY_8_ALLOWED')}</div>
          </div>

        )
      }
    </>
  );
});

export default Guarantors;
